import { Region } from './plotting/region';

export enum State {
  SUBMITTED = 'SUBMITTED',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  NONE = 'NONE',
  CANCELLED = 'CANCELLED'
}

export enum Action {
  IMAGE_CROP_STARTED = 'IMAGE_CROP_STARTED',
  IMAGE_UPLOAD_RUNNING = 'IMAGE_UPLOAD_RUNNING',
  IMAGE_CROP_COMPLETE = 'IMAGE_CROP_COMPLETE',
  NONE = 'NONE',
  INIT = 'INIT',
  FIBERS_FOUND = 'FIBERS_FOUND',
  NUCLEI_FOUND = 'NUCLEI_FOUND',
  SAVED = 'SAVED',
}

export interface IStatus {
  state: State;
  // action: Action;
  complete?: number;
  completePercent: number;
  message?: string;
  errorStack?: string;

  workflowId: string,
  userName: string,
  info: string, // process name
  submissionInput: string | null,
  results: string[] | null,
  action: string,
  warnings: string[] | null,
  regionImages: string[] | null,
}

export type Status = Partial<IStatus> & Required<Pick<IStatus, 'completePercent'>>

export function isActive(state: State): boolean {
  if (state === State.NONE || state === State.ERROR || state === State.COMPLETE) {
    return false;
  }
  return true;
}

export function isRunning(state: State): boolean {
  if (state === State.ERROR || state === State.COMPLETE) {
    return false;
  }
  return true;
}

export function getImage(status: Status): string | null {
  let images: string[] = [];

  if (status as SegmentationStatus) {
    images = (status as SegmentationStatus).regionImages;
  }
  if (status as QuantStatus) {
    images = (status as QuantStatus).proofImages;
  }

  if (images == null || images.length < 1) {
    return null;
  } else {
    return images[0];
  }
}

export class QuantStatus implements Status {
  state = State.NONE;
  action = Action.NONE;
  complete = 0.0;
  completePercent = 0.0;
  proofImages: string[] = [];
  fiberResults?: string;
  nucleiResults?: string;
}

export class SegmentationStatus implements Status {
  state = State.NONE;
  action = Action.NONE;
  complete = 0.0;
  completePercent = 0.0;
  regionImages: string[] = [];
  warnings?: string[] = [];
  regions?: Region[] = [];
}

export class StringResponse {
  response: string | undefined;
}
