import { Injectable } from '@angular/core';
import { MessageService, TreeNode, MenuItem } from 'primeng/api';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Status } from './models/status';

import { MainState } from './main.state';
import { DisplayType, FileProject, IFileInfo, ImageInfo } from './models/file-info';
import { FileInfoFactory } from './models/file-info/file-info.factory';
import { FilesService } from '../services/files.service';
import { ProcessService } from '../services/process.service';
import {
  CropRequest,
  Instruction,
  ProcessType,
  QuantRegion,
  QuantRequest,
  Request,
  ValidationType,
  ZoomRequest
} from './models/request';
import { Polygon } from './models/plotting/region';
import { StringResponse } from './models/status';
import { TableData } from './models/table-data';
import { ProcessStatusFacade } from './components/process/process-status/process-status.facade';
import { DownloadService } from '../download/download.service';
import { Download } from '../download/download';

@Injectable({
  providedIn: 'root',
})
export class MainFacade {

  private diagramComponent: any = null;

  constructor(private mainState: MainState,
              private filesService: FilesService,
              private processService: ProcessService,
              private downloadService: DownloadService,
              private messageService: MessageService,
              private processStatusFacade: ProcessStatusFacade) {

    this.mainState.getSelectedProject$().subscribe(
      (project) => {
        this.mainState.setSelectedNode(null);
        this.mainState.setRootNodes([]);
        if (project) {
          this.loadChildren(project.rawData);
        }
      }
    );
    mainState.getDiagramData$().subscribe(
      (data) => {
        this.diagramComponent = data;
      }
    );
  }

  /**
   * calls the service to fetch all active projects, transforms the
   * received data into entities and saves the projects in observable
   * note: executes post authentication
   */
  loadProjects() {
    this.filesService.getProjects().subscribe((rawFile) => {
      const projects: FileProject[] = [];
      rawFile.forEach(rawFile => {
        projects.push(FileInfoFactory.getFileInfo(rawFile));
      });
      this.mainState.setProjects$(projects);
    });
  }

  createMainTabMenu(items: MenuItem[]) {
    this.mainState.setTabMenuItems$(items);
  }

  getMainTabMenu$(): Observable<MenuItem[]> {
    return this.mainState.getMainTabMenu$();
  }

  selectTabMenuItemL$(item: MenuItem) {
    this.mainState.setSelectedTabItemL$(item);
  }

  selectTabMenuItemR$(item: MenuItem) {
    this.mainState.setSelectedTabItemR$(item);
  }

  getSelectedTabMenuItemL$(): Observable<MenuItem | null> {
    return this.mainState.getSelectedTabMenuItemL$();
  }

  getSelectedTabMenuItemR$(): Observable<MenuItem | null> {
    return this.mainState.getSelectedTabMenuItemR$();
  }

  getProjects$(): Observable<FileProject[]> {
    return this.mainState.getProjects$();
  }

  getSelectedProject$(): Observable<FileProject | null> {
    return this.mainState.getSelectedProject$();
  }

  getFileInfo$(): Observable<IFileInfo[]> {
    return this.mainState.getFileInfo$();
  }

  getSelectedNode$(): Observable<TreeNode<IFileInfo> | null> {
    return this.mainState.getSelectedNode$();
  }

  getFileContent$(): Observable<string | null> {
    return this.mainState.getFileContent$();
  }

  getFileLoading$(): Observable<boolean> {
    return this.mainState.isFileLoading$();
  }

  getTableData$(): Observable<TableData> {
    return this.mainState.getTableData$();
  }

  isTreeLoading$(): Observable<boolean> {
    return this.mainState.isFileTreeLoading$();
  }

  isFileLoading$(): Observable<boolean> {
    return this.mainState.isFileLoading$();
  }

  isImageLoading$() {
    return this.mainState.isImageLoading$();
  }

  setImageLoading$(isImageLoading: boolean) {
    this.mainState.setImageLoading(isImageLoading);
  }

  getImageLoadingMessage() {
    return this.mainState.getImageLoadingMessage$();
  }
  setImageLoadingMessage(imageLoadingMessage: string) {
    this.mainState.setImageLoadingMessage(imageLoadingMessage);
  }

  isImageCached() {
    return this.mainState.isImageCached$();
  }

  isZoom() {
    return this.mainState.isZoom$();
  }
  setZoom(zoom: boolean) {
    this.mainState.setZoom(zoom);
  }

  getFilename() {
    return this.mainState.getFilename();
  }
  setFilename(filename: string) {
    this.mainState.setFilename(filename);
  }

  isLoadingError$(): Observable<boolean> {
    return this.mainState.isLoadingError$();
  }

  getProcessing$(): Observable<boolean> {
    return this.mainState.isProcessing$();
  }

  getProcessingStatus$() {
    return this.mainState.getProcessingStatus$();
  }

  getPreviewUrl$(): Observable<string | null> {
    return this.mainState.getPreviewUrl$();
  }

  getPreviewSlicesUrls$(): Observable<string[] | undefined> {
    return this.mainState.getPreviewSlicesUrls$();
  }

  getImageSize$(): Observable<any> {
    return this.mainState.getImageSize$();
  }

  setImageInfo(imageInfo: ImageInfo) {
    this.mainState.setImageInfo$(imageInfo);
  }
  getImageInfo$(): Observable<ImageInfo | null> {
    return this.mainState.getImageInfo$();
  }

  selectProject(project: FileProject) {
    this.mainState.setSelectedProject$(project);
  }

  setDiagram(diagram: any) {
    this.mainState.setDiagramData(diagram);
  }

  getSelectedFileInfo(): IFileInfo | undefined {
    return this.filesService.getSelectedFileInfo();
  }

  isFileSelected(): Observable<boolean> {
    return this.filesService.isFileSelected$();
  }

  setPanelWidth(value: number) {
    this.mainState.setPanelWidth(value);
  }
  getPanelWidth(): Observable<number> {
    return this.mainState.getPanelWidth();
  }

  isSelectedFileFolder$(): Observable<boolean | null> {
    return this.mainState.getSelectedFile$().pipe(
      map((info) => {
        return info == null ? null : info?.type == 'Folder';
      })
    );
  }

  getRootNodes$(): Observable<TreeNode<IFileInfo>[]> {
    return this.mainState.getRootNodes$();
  }

  getValidationMessage$(): Observable<string | null> {
    return this.mainState.getValidationMessage$();
  }

  openZoomSession(file: IFileInfo): Observable<any> {
    return this.filesService.openZoomSession(file);
  }

  getZoomRegion(zoomRequest: ZoomRequest): Observable<any> {
    return this.filesService.zoomOnRegion(zoomRequest);
  }

  selectDisplayType(displayType: DisplayType | null) {
    this.filesService.selectDisplayType(displayType);
  }

  updateNode(node: any): void {
    this.mainState.setSelectedNode(node);
    if (node == null || node.data == null) {
      return;
    }
    const parent = node.data;

    // We always make the list again. Disk
    // may have changed.
    node.children.length = 0;
    this.loadChildren(parent.rawData);
  }

  loadChildren(parent: any) {
    this.mainState.setFileTreeLoading(true);
    this.filesService.getChildren(parent).subscribe({
      next: files => {
        const fileArr: IFileInfo[] = [];
        const len = files.length;
        for (let i = 0; i < len; i++) {
          fileArr.push(FileInfoFactory.getFileInfo(files[i]));
        }
        // this.addNodes(parent, fileArr);
        this.mainState.setFileInfo$(fileArr);
      },
      complete: () => {
        this.mainState.setFileTreeLoading(false);
      }
    });
  }

  nodeSelect(node: TreeNode<IFileInfo>): void {
    this.mainState.setSelectedNode(node);
  }

  /**
   * Gets the currently selected file information (or 'undefined')
   * and fetches that file's content from the source service.
   */
  fetchContent(): void {
    const selectedFile = this.filesService.getSelectedFileInfo();
    const file: IFileInfo | undefined = selectedFile;

    this.mainState.setFileLoading(true);

    if (file) {
      this.filesService.getContent(file.rawData).subscribe({
        next: (content: string) => {
          try {
            const object = JSON.parse(content);
            // prettify JSON
            content = JSON.stringify(object, null, 4);
          } catch (err) {
            console.error(err);
          }
          this.mainState.setFileContent(content);
          this.mainState.setFileLoading(false);
        },
        error: (error) => {
          console.error(`error loading file content: ${error.message}`);
          this.mainState.setFileLoading(false);
        },
        complete: () => {
          this.mainState.setFileLoading(false);
        }
      });
    }
  }

  fetchTableData(): void {
    const file: IFileInfo | undefined = this.filesService.getSelectedFileInfo();

    this.mainState.setFileLoading(true);

    if (file) {
      this.filesService.getTableData(file.rawData).subscribe({
        next: (data) => {
          this.mainState.setTableData(data);
        },
        error: (error) => {
          console.error(`error loading table data: ${error.message}`);
        },
        complete: () => {
          this.mainState.setFileLoading(false);
        }
      })
    }
  }

  deleteSelected() {
    const selected = this.filesService.getSelectedFileInfo();
    if (selected) {
      this.filesService.deleteFile(selected.rawData).subscribe({
        next: () => {
          this.messageService.add({ key: 'app-toast', severity: 'success', summary: 'Delete Success', detail: `"${selected.name}" was successfully deleted.` });
        },
        error: () => {
          this.messageService.add({ key: 'app-toast', severity: 'warn', summary: 'Cannot Delete', detail: `"${selected.name}" could not be deleted.` });
        },
        complete: () => {
          const node = this.filesService.getSelectedNode();
          if (node != null) {
            if (node.parent && node.parent.children) {
              const index = node.parent.children!.indexOf(node);
              node.parent.children!.splice(index, 1);
            } else {
              const rootNodes = this.filesService.getRootNodes();
              const index = rootNodes.indexOf(node);
              rootNodes.splice(index, 1);
              this.filesService.setRootNodes(rootNodes);
            }
          }
        }
      });
    }
  }

  interuptPreview(): void {
    console.log('interrupt preview');
  }

  processSelected(): void {
    console.log('process selected');
  }

  // TODO - can remove as everything is run and monitored in activity queue
  queueProcess(request: Request): void {
    this.runProcess(request);
  }

  /**
   * Run a process with a given Request.
   *
   * @param request
   */
  runProcess(request: Request): void {

    this.configRequest(request);
    const processType = this.hackRequest(request);
    const instruction = this.createInstruction(request);

    if (instruction) {
      this.validateAndRun(processType, instruction);
    }
  }

  /**
   * Find regions in the current image (if any)
   */
  find(request: Request, observer: Observer<Status>) {
    const processType = this.hackRequest(request);
    const instruction: Instruction | null = this.createInstruction(request);
    if (instruction === null) {
        return;
    }
    // TODO We may want to switch off the messages here and show
    // something else like a progress bar because this find will
    // be quick.
    this.process(processType, instruction, true, observer)
  }

  /**
   * Create the Instruction from the given Request parameter to be sent to the process service.
   *
   * @param request
   */
  createInstruction(request: Request): Instruction | null {
    const fileInfo: IFileInfo | undefined = this.filesService.getSelectedFileInfo();
    const rawData = (fileInfo?.rawData) ? fileInfo.rawData : undefined;
    if (rawData) {
      return {
        file: rawData,
        request: request
      };
    } else {
      return null;
    }
  }

  /**
   * Validate the given Instruction before sending it to the process service.
   *
   * @param processType
   * @param instruction
   */
  validateAndRun(processType: ProcessType, instruction: Instruction): void {
    this.processService.validate(processType, instruction).subscribe({
      next: (validation) => {
        let runProcess = false;
        if (validation.type === ValidationType.VALID) {
          this.mainState.setValidationMessage(null);
          runProcess = true;
        } else if (validation.type === ValidationType.LIKELY_VALID) {
          console.warn(validation.message);
          this.mainState.setValidationMessage(null);
          runProcess = true;
        } else {
					if (validation.type === ValidationType.LIKELY_INVALID) {
						this.warningMessage('Warning Parameters', `${validation.message}`);
					} else {
						this.errorMessage('Invalid Parameters', `${validation.message}`);
					}
          this.mainState.setValidationMessage(validation.message);
        }

        if (runProcess) {
          this.process(processType, instruction, true, this.createStatusObserver());
        }
      },
      error: (error) => {
        this.errorMessage('Validation Failed', 'System was unable to validate the processing request');
        this.mainState.setValidationMessage('Validation request failed');
      }
    });
  }

  createStatusObserver() : Observer<Status> {
    return {
      next: () => {
        // Do Nothing
      },
      error: error => {
        console.error(`Error in submit observer: ${error}`);
      },
      complete: () => {
        // ok
      }
    }
  }

  /**
   * Sent the Instruction to the process service and manage the response.
   *
   * @param processType
   * @param instruction
   */
  process(processType: ProcessType, instruction: Instruction,
            requireMessage: boolean = true,  observer: Observer<Status>) {

        this.mainState.setProcessing(true);
        this.processService.run(processType, instruction).subscribe({
        next: (res: StringResponse) => {


          if (requireMessage) {
            const summary = 'Submit Success';
            const content = `${processType} process submitted.\n\nPlease visit 'Queue' to see progress.`;
            this.successMessage(summary, content);
          }
          if (res != null && res.response != undefined) {
            this.processService.monitor(new URL(res.response), observer);
          }
        },
        error: () => {
          const summary = 'Submit Failed';
          const content = '${processType} process failed to submit';
          if (requireMessage) {
            this.errorMessage(summary, content);
          }
        }
      });
  }

  /**
   * The service does not accept a request if the process type is included. It's actually only the EDoF
   * process that fails when it is included so we should request that to be fixed and we would no longer
   * need this function.
   *
   * @param request
   */
  hackRequest(request: Request): ProcessType {
    const processType = request.processType;
    // eslint-disable-next-line
    // @ts-ignore
    delete request.processType;
    return processType;
  }

  // TODO - this all shoud go into the Segmentation and HTQuant components which allows us to remove the
  // diagram component from the Facade
  private configRequest(request: Request) {
    if (request instanceof CropRequest) {
      const cropRequest = <CropRequest>request;
      // This is needed so that the cropping properly works
      // otherwise the backend defaults it to -1
      cropRequest.regionSettings.manualRegionResolution = 0;

      if (this.diagramComponent && this.diagramComponent.hasRegions()) {
        cropRequest.regionSettings.manualRegions = this.diagramComponent.getRegionPolygons();
      } else {
        cropRequest.regionSettings.manualRegions = [];
      }
      request = cropRequest;
    } else if (request instanceof QuantRequest) {
      const quantRequest = <QuantRequest>request;
      let regionCount = 0;
      if (this.diagramComponent.hasRegions()) {
        // This is needed so that the cropping properly works
        // otherwise the backend defaults it to -1
        quantRequest.regionSeries = 0;
        const polygons: Polygon[] = this.diagramComponent.getRegionPolygons();
        if (polygons != null) {
          quantRequest.regions = [];
          polygons.forEach((poly: Polygon) => {
            if (poly == null) return;
            const region: QuantRegion = new QuantRegion();
            region.polygon = poly;
            regionCount += 1;
            region.name = 'Region_' + regionCount;

            quantRequest.regions.push(region);
          });
        }
      }
      request = quantRequest;

      // not sure if this is needed
      // if (quantRequest.outputName!=null) {
      //   this.previousOutputNames.push(quantRequest.outputName);
      // }
    }
  }

  download(): Observable<Download> | null {
    const selectedFile = this.filesService.getSelectedFileInfo();
    if (selectedFile) {
      const url: string | null = this.filesService.getDownloadUrl(selectedFile);
      if (url) {
        return this.downloadService.download(url, selectedFile.name);
      } else {
        this.errorMessage('Download Error', `There was an error downloading file ${selectedFile.name}`);
      }
    }
    return null;
  }

  public successMessage(title: string, content: string) {
    this.showMessage(title, content, 'success');
  }

  public warningMessage(title: string, content: string) {
    this.showMessage(title, content, 'warn');
  }

  public errorMessage(title: string, content: string) {
    this.showMessage(title, content, 'error');
  }

  private showMessage(title: string, content: string, severity: string) {
    this.messageService.add({ key: 'app-toast', severity: severity, summary: title, detail: content });
  }

  public setFileInfoZoomSession(fileInfoZoomSession: string) {
    this.mainState.setFileInfoZoomSession(fileInfoZoomSession);
  }
  public getFileInfoZoomSession() {
    return this.mainState.getFileInfoZoomSession();
  }

}
