<h4 class="font-light text-base mb-3 mt-1">
  Select .NDPI image(s) to process.
</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>Fibers</b>
    </div>
  </p-divider>

  <div formGroupName="fiberSettings">
    <!-- minimum area - fibers -->
    <div class="field grid align-items-center my-1">
      <label for="min" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Minimum Area (um<sup>2</sup>):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="minFibers"
          formControlName="min"
          [minFractionDigits]="0"
          [maxFractionDigits]="3"
          [showButtons]="true"
          [min]="0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- maximum area - fibers -->
    <div class="field grid align-items-center my-1">
      <label for="max" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Maximum Area (um<sup>2</sup>):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="maxFibers"
          formControlName="max"
          [minFractionDigits]="0"
          [maxFractionDigits]="3"
          [showButtons]="true"
          [min]="0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- minimum circularity - fibers -->
    <div class="field grid align-items-center my-1">
      <label for="cMin" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Minimum Circularity (%):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="cMinFibers"
          formControlName="cMin"
          [showButtons]="true"
          [min]="0"
          [max]="100"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- maximum circularity - fibers -->
    <div class="field grid align-items-center my-1">
      <label for="cMax" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Maximum Circularity (%):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="cMaxFibers"
          formControlName="cMax"
          [showButtons]="true"
          [min]="0"
          [max]="100"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- hard area bounds checkbox - fibers -->
    <div class="field-checkbox">
      <p-checkbox
        formControlName="hardBounds"
        [binary]="true"
        class="my-1"
        inputId="hardBoundsFibers"
      ></p-checkbox>
      <label for="hardBoundsFibers">
        Hard area bounds
        <i class="pi pi-info-circle px-1"
          pTooltip="Values outside the min and max area will be excluded"
          tooltipPosition="top"
        ></i>
      </label>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>Nuclei</b>
    </div>
  </p-divider>

  <div formGroupName="nucleiSettings">
    <!-- minimum area - nuclei -->
    <div class="field grid align-items-center my-1">
      <label for="min" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Minimum Area (um<sup>2</sup>):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="minNuclei"
          formControlName="min"
          [minFractionDigits]="0"
          [maxFractionDigits]="3"
          [showButtons]="true"
          [min]="0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- maximum area - nuclei -->
    <div class="field grid align-items-center my-1">
      <label for="max" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Maximum Area (um<sup>2</sup>):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="maxNuclei"
          formControlName="max"
          [minFractionDigits]="0"
          [maxFractionDigits]="3"
          [showButtons]="true"
          [min]="0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- minimum circularity - nuclei -->
    <div class="field grid align-items-center my-1">
      <label for="cMin" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Minimum Circularity (%):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="cMinNuclei"
          formControlName="cMin"
          [showButtons]="true"
          [min]="0"
          [max]="100"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <!-- maximum circularity - nuclei -->
    <div class="field grid align-items-center my-1">
      <label for="cMaxNuclei" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Maximum Circularity (%):
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="cMaxNuclei"
          formControlName="cMax"
          [showButtons]="true"
          [min]="0"
          [max]="100"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <div class="field-checkbox">
      <p-checkbox
        formControlName="hardBounds"
        [binary]="true"
        class="my-1"
        inputId="hardBoundsNuclei"
      ></p-checkbox>
      <label for="hardBoundsNuclei">
        Hard area bounds
        <i class="pi pi-info-circle px-1"
          pTooltip="Values outside the min and max area will be excluded"
          tooltipPosition="top"
        ></i>
      </label>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>Contrast</b>
    </div>
  </p-divider>

  <div formGroupName="contrastProperties">

    <div class="field-checkbox">
      <p-checkbox
        formControlName="stretch"
        [binary]="true"
        class="my-1"
        inputId="stretchHisto"
      ></p-checkbox>
      <label for="stretchHisto">
        Stretch histogram
        <i class="pi pi-info-circle px-1"
          pTooltip="Check to set the histogram to strech during fiber finding."
          tooltipPosition="top"
        ></i>
      </label>
    </div>

    <div class="field grid align-items-center my-1">
      <label for="satSpinner" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Saturation:
		<i class="pi pi-info-circle px-1"
          pTooltip="Saturation controls the min and max for the histogram stretch."
          tooltipPosition="top"
        ></i>
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="satSpinner"
          formControlName="saturated"
          [showButtons]="true"
          [min]="0.0"
          [max]="255.0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

    <div class="field-checkbox">
      <p-checkbox
        formControlName="normalize"
        [binary]="true"
        class="my-1"
        inputId="norm"
      ></p-checkbox>
      <label for="norm">
        Normalize histogram
        <i class="pi pi-info-circle px-1"
          pTooltip="Normalize during stetching the histogram."
          tooltipPosition="top"
        ></i>
      </label>
    </div>

    <div class="field-checkbox">
      <p-checkbox
        formControlName="gammaCorrection"
        [binary]="true"
        class="my-1"
        inputId="gammaCor"
      ></p-checkbox>
      <label for="gammaCor">
        Gamma correction
        <i class="pi pi-info-circle px-1"
          pTooltip="Use a gamma correction for images with low contrast."
          tooltipPosition="top"
        ></i>
      </label>
    </div>

   <div class="field grid align-items-center my-1">
      <label for="gammaEntry" class="col-12 mb-2 md:col-7 md:mb-0 text-black-alpha-90">
        Gamma:
		<i class="pi pi-info-circle px-1"
          pTooltip="Gamma correction value for shifting intensity during nuceli finding."
          tooltipPosition="top"
        ></i>
      </label>

      <div class="col-12 md:col-5">
        <p-inputNumber
          inputId="gammaEntry"
          formControlName="gamma"
          [showButtons]="true"
          [minFractionDigits]="0"
          [maxFractionDigits]="3"
          [min]="0.05"
          [max]="5.0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>

  </div>

  <ng-container *ngIf="bucket">
  <p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>File Destination</b>
    </div>
  </p-divider>

  <p class="font-light text-xs my-2">
    Fibers and Nuclei creates 3 files for each Region of Interest (ROI).
    <br><br>
    Indicate which folder you would like the files to be stored in.
  </p>

  <!-- storage bucket label -->
  <div class="field grid py-0">
    <label for="fn-bucket"
      class="col-12 mb-2 md:col-4 md:mb-0 right-0 text-black-alpha-90 text-sm">
      Storage Bucket:
    </label>
    <div class="col-12 md:col-8 text-sm">
      <span id="fn-bucket">{{bucket}}</span>
    </div>
  </div>

  <div class="pt-3 pb-3">
    <span *ngIf="validationMessage" style="color: red">{{ validationMessage }}</span>
  </div>

  <!-- directory path label -->
  <div *ngIf="parentFolderPath" class="field grid py-0">
    <label for="fn-parent-folder"
           class="col-12 mb-2 md:col-4 md:mb-0 right-0 text-black-alpha-90 text-sm">
      Parent Folder:
    </label>
    <div class="col-12 md:col-8 text-sm">
      <span id="fn-parent-folder">{{parentFolderPath}}</span>
    </div>
  </div>

  <!-- output folder field -->
  <div class="field grid h-3rem align-items-center">
    <label for="fnOutputFolder" class="col-12 md:col-5 mb-0 text-black-alpha-90">
      Output Folder:
    </label>
    <div class="col-12 md:col-7">
      <span *ngIf="!isOutputFolderFiledEditable">
        {{form.controls['outputName'].value}}
        <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputFolderName(true)"></i>
      </span>
      <span *ngIf="isOutputFolderFiledEditable" class="p-input-icon-right">
        <i class="pi pi-times icon-hover" (click)="onEditOutputFolderName(false)"></i>
        <input id="fnOutputFolder" type="text" class="p-inputtext-sm w-full"
               formControlName="outputName" pInputText/>
      </span>
    </div>
  </div>
  </ng-container>

  <div class="process-buttons">
    <p-button type="submit" icon="pi pi-play"
              tooltipPosition="top"
              label="Run"
              styleClass="p-button-primary"
    ></p-button>
  </div>

</form>
