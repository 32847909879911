<p-toolbar xmlns="http://www.w3.org/1999/html">
  <div class="p-toolbar-group-start">
    <p-button pRipple class="mr-2" (onClick)="onSelectionEditClick()" pTooltip="Add or Edit Selection regions"
              tooltipPosition="bottom">
      <img alt="logo" src="../../../../assets/icons/edit-selection-white.svg" style="width: 1rem"/>
      <span class="ml-2 font-bold">ROI Editor</span>
    </p-button>
    <p-toggleButton pRipple [(ngModel)]="scaleratio"
                    onLabel="1:1" offLabel="1:X"
                    pTooltip='Toggle between 1:1 scale ratio and non-scaled ratio image.
                    Note: the zoom selection rectangle will be a square in the 1:1 mode.
                    In order to have a rectangle of any size, the 1:x mode needs to be on.'
                    tooltipPosition="bottom"
                    class="mr-2" (onChange)="selectScaleRatio($event.checked)">
    </p-toggleButton>
    <i class="pi pi-info-circle px-1 mr-2"
       pTooltip="Toggle between 1:1 scale ratio and non-scaled ratio image.
                    Note: the zoom selection rectangle will be a square in the 1:1 mode.
                    In order to have a rectangle of any size, the 1:x mode needs to be on."
       tooltipPosition="bottom"
    ></i>
  </div>
  <span *ngIf="imageInfo?.isGrayscale" class="mr-2">
    <div class="p-toolbar-group-start">
      <p-toggleButton pRipple [(ngModel)]="reversescale"
                      onIcon="pi pi-palette" offIcon="pi pi-palette"
                      pTooltip="Toggle between normal and reverse color scale" tooltipPosition="bottom"
                      class="mr-2" (onChange)="selectReverseScale($event.checked)">
      </p-toggleButton>

      <p-dropdown [options]="colormapsOptions" [(ngModel)]="selectedColormap"
                  pTooltip="List of color maps"
                  (onChange)="selectColormap($event.value)">
        <ng-template pTemplate="selectedItem">
          <div class="flex align-items-center gap-2" *ngIf="selectedColormap">
            <img [src]="selectedColormap.src" class="colormap" />
            <div>{{ selectedColormap.name }}</div>
          </div>
        </ng-template>
        <ng-template let-colormap pTemplate="item">
            <div class="flex align-items-center gap-2">
              <img [src]="colormap.src" class="colormap" />
              <div>{{ colormap.name }}</div>
            </div>
        </ng-template>
      </p-dropdown>
    </div>
  </span>
  <span *ngIf="imageInfo?.isStack">
    <div class="p-toolbar-group-start">
      <p-dropdown [options]="stackOptions" [(ngModel)]="selectedStackOption"
                  optionLabel="name"
                  placeholder="Select image mode"
                  pTooltip="View image as a stack or show only one image"
                  (onChange)="selectStackOption($event.value)" class="mr-2"></p-dropdown>
      <i class="pi pi-info-circle px-1 mr-2"
         pTooltip="View image as a stack or show only one image. Selecting single image mode enables high-def
                  zoom on selected images in the stack. The stack image mode enables slicing through each
                  image in the stack. The zoom in that mode is a low-def zoom."
         tooltipPosition="bottom"
      ></i>
      <div class="flex" *ngIf="!imageInfo?.showStack">
        <div class="flex-initial flex align-items-center justify-content-center mr-2">
          Slice
        </div>
        <div class="flex-initial flex align-items-center justify-content-center">
          <div class="p-inputgroup mr-2">
            <p-inputNumber styleClass="w-3rem" [(ngModel)]="zIndex" [min]="0" [max]="maxIndex"
                           (onInput)="updateZIndex()"/>
            <button type="button" pButton pRipple icon="pi pi-refresh" (click)="reloadAndPlot()"
                  pTooltip="Plot slice number of the stack" tooltipPosition="bottom"></button>
          </div>
        </div>
        <div class="flex-initial flex align-items-center justify-content-center">
          of {{maxIndex}}
        </div>
      </div>
    </div>
  </span>
  <div class="p-toolbar-group-end">
    <p-button pRipple styleClass="p-button-outlined p-button-rounded mr-2" (onClick)="showHelp()"
              icon="pi pi-question" iconPos="left" pTooltip="Help" tooltipPosition="bottom">
    </p-button>
  </div>
</p-toolbar>
<p-dialog header="Plotting help" [(visible)]="displayHelpDialog" [modal]="true" [style]="{width: '50vw'}"
          [maximizable]="true" [draggable]="false" [resizable]="false">
  <p>After having clicked on an image on the left hand side file-tree, the image should be shown in this
    plotting system as a heatmap. The plotting allows for "real zooming" (similar to GoogleMap when displaying
    image data - the resolution of the larger shown image is downscaled, and everytime a zoom-in occurs, a
    higher resolution image is shown until the original image pixels can be shown). <br>
    <br> The plotting system controls are made of this main menu bar where the "<b>ROI Editor</b>",
    the "<b>scale-ratio</b>", the "<b>colormap</b>", and the image mode (when a stack is opened) can be
    changed and edited. The "colormap" tools include a "<b>reverse</b>" <i class="pi pi-palette"></i>
    toggle and a dropdown with a list of different colormaps and are available only when displaying a
    grayscale image.
  </p>
  <p>
  The other controls include the tool bar, where by default not tool is selected (all greyed-out), and
    which allows
  </p>
    <ul>
      <li>"<b>image download</b>" <object data="assets/icons/camera.svg" width="15" height="15">
        </object>: download the image as a 'png'.
      </li>
      <li>"<b>autoscale</b>" <object data="assets/icons/autoscale.svg" width="15" height="15"></object>:
        resets the image to its original dimension. If in stack mode, it will reset the image mode
        to single image mode.
      </li>
      <li>
        "<b>axes reset</b>" <object data="assets/icons/home.svg" width="15" height="15"></object>: resets the
        axes to the original image dimensions.
      </li>
      <li>
        "<b>zoom selection</b>" <object data="assets/icons/zoom.svg" width="15" height="15"></object>:
        activate zoom mode to perform a zoom selection on the image. If in single-image mode, or any image
        that is not a stack, the mouse selection will trigger a high-def zoom, creating a cache of the image
        the first time (longer loading); then the next zooming events will be faster than the
        first one.
      </li>
      <li>
        "<b>panning</b>" <object data="assets/icons/pan.svg" width="15" height="15"></object>: activate
        panning image mode to perform a panning selection; dragging and releasing the mouse on a zoomed
        image will trigger a new new zoom event on the new area of the image selected.
      </li>
      <li>
        "<b>zoom-in</b>" <object data="assets/icons/zoomin.svg" width="15" height="15"></object>: triggers the
        zoom-in event where the zoom selection is automatically set.
      </li>
      <li>
        "<b>zoom-out</b>" <object data="assets/icons/zoomout.svg" width="15" height="15"></object>: triggers
        the zoom-out event where the zoom selection is automatically set. The last zoom selection will
        stop at the original image dimensions.
      </li>
      <li>
        "<b>Freeform region creation</b>" <object data="assets/icons/freeform.svg" width="15" height="15">
        </object>:
        activates the freeform region mode which enables the user to create a freeform selection using the
        mouse, by pressing, dragging and releasing the left-mouse button.
      </li>
      <li>
        "<b>rectangular region creation</b>" <object data="assets/icons/rectangle.svg" width="15" height="15">
        </object>: activates the rectangular region mode which enables the user to create a rectangular
        selection using the mouse, by pressing, dragging and releasing the left-mouse button.
      </li>
      <li>
        "<b>active region deletion</b>" <object data="assets/icons/erase.svg" width="15" height="15">
        </object>: deletes the selected region. This can also be done using the ROI editor dialog.
      </li>
    </ul>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayHelpDialog=false" label="Ok" styleClass="p-button-text">
    </p-button>
  </ng-template>
</p-dialog>
<div class="w-full h-full relative" id="plot">
  <div *ngIf="imgLoading || stackLoading" id="overlay">
    <div *ngIf="imgLoading" class="spinner"></div>
    <span *ngIf="imgLoading && !zoom" class="text-loading">{{loadingMessage}}</span>
    <span *ngIf="imgLoading && zoom" class="zoom-text-loading">{{loadingMessage}}</span>
    <p-progressBar class="progress" *ngIf="stackLoading" [value]="loadingPercentage"></p-progressBar>
    <span *ngIf="stackLoading" class="text-loading">Loading frames...</span>
    <p-button *ngIf="stackLoading || imgLoading" class="cancel-loading " label="Cancel"
              (onClick)="cancelLoading()">
    </p-button>
  </div>
</div>

