import { TreeNode } from 'primeng/api';

import { BasicFile, FileFolder, FileProject, IFile, IFileInfo, ImageFile } from './models/file-info';
import { ProcessType } from './models/request';

export const CHEVRON_CIRCLE_DOWN_ICON_STYLE = 'pi pi-chevron-circle-down';
export const CHEVRON_CIRCLE_RIGHT_ICON_STYLE = 'pi pi-chevron-circle-right';
export const CODE_ICON_STYLE = 'pi pi-code';
export const EXCEL_ICON_STYLE = 'pi pi-file-excel';
export const FILE_ICON_STYLE = 'pi pi-file';
export const FOLDER_ICON_STYLE = 'pi pi-folder';
export const IMAGE_ICON_STYLE = 'pi pi-image';
export const IMAGES_ICON_STYLE = 'pi pi-images';
export const OPEN_FOLDER_ICON_STYLE = 'pi pi-folder-open';
export const WINDOW_MAXIMIZE_ICON_STYLE = 'pi pi-window-maximize';

export class MainUtilities {
  public createNodes(files: IFileInfo[]): TreeNode<IFileInfo>[] {
    const nodes: TreeNode<IFileInfo>[] = [];

    files.forEach(file => {
      const node = this.createNodeFromFile(file);
      nodes.push(node);
    });

    return nodes;
  }

  public createNodeFromFile(file: IFileInfo): TreeNode {
    const node: TreeNode = this.createDefaultTreeNode(file);

    if (file instanceof FileProject) {
      this.decorateProjectNode(node);
    } else if (file instanceof ImageFile) {
      const imageFile: ImageFile = <ImageFile>file;
      this.decorateImageFileNode(imageFile, node);
    } else if (file instanceof BasicFile) {
      const basicFile: BasicFile = <BasicFile>file;
      this.decorateBasicFileNode(basicFile, node);
    } else if (file instanceof FileFolder) {
      const folder: FileFolder = <FileFolder>file;
      this.decorateFileFolder(folder, node);
    } else {
      console.error(`Invalid IFileInfo for generating node: ${JSON.stringify(file)}`);
    }

    return node;
  }

  private createDefaultTreeNode(file: IFileInfo): TreeNode {
    // Limit size of any preview generated from node.
    // file.height = 1024;
    return {
      data: file,
      leaf: file.type == 'File',
      label: file.name,
      children: []
    };
  }

  private decorateProjectNode(node: TreeNode) {
    node.expandedIcon = OPEN_FOLDER_ICON_STYLE;
    node.collapsedIcon = FOLDER_ICON_STYLE;
  }

  private decorateImageFileNode(imageFile: ImageFile, node: TreeNode) {
    if (imageFile.results) {
      node.icon = IMAGES_ICON_STYLE;
    } else {
      node.icon = IMAGE_ICON_STYLE;
    }
  }

  private decorateBasicFileNode(basicFile: BasicFile, node: TreeNode) {
    if (basicFile.results) {
      if (basicFile.fileExtension === 'csv') {
        node.icon = EXCEL_ICON_STYLE;
      } else {
        console.error(`Unexpeted results file type: ${JSON.stringify(basicFile)}`);
      }
    } else if (this.isText(basicFile)) {
      node.icon = CODE_ICON_STYLE;
    } else {
      node.icon = FILE_ICON_STYLE;
    }
  }

  private decorateFileFolder(folder: FileFolder, node: TreeNode) {
    if (folder.results) {
      if (folder.processType === ProcessType.CROP) {
        node.expandedIcon = WINDOW_MAXIMIZE_ICON_STYLE;
        node.collapsedIcon = WINDOW_MAXIMIZE_ICON_STYLE;
      } else if (folder.processType === ProcessType.HTQUANT) {
        node.expandedIcon = CHEVRON_CIRCLE_DOWN_ICON_STYLE;
        node.collapsedIcon = CHEVRON_CIRCLE_RIGHT_ICON_STYLE;
      } else {
        console.error(`Invalid FileInfo for generating folder node: ${JSON.stringify(folder)}`);
      }
    } else {
      node.expandedIcon = OPEN_FOLDER_ICON_STYLE;
      node.collapsedIcon = FOLDER_ICON_STYLE;
    }
  }

  public isText(file: IFile) : boolean {
    const extension: string | null = file.fileExtension;
    return (extension === 'json' || extension == 'xml' || extension == 'txt');
  }

  /**
   * Deep copy of an object so that the new copied object if modified
   * does not modify the original array.
   * @param items
   */
  public deepClone<T>(items: T[]): T[] {
    return items.map(item => {
      if (Array.isArray(item)) {
        return this.deepClone(item);  // Handle nested arrays
      } else if (typeof item === 'object' && item !== null) {
        // Correctly cloning each object property
        const clonedObject: any = {};
        for (const key in item) {
          clonedObject[key] = this.deepClone([item[key]])[0];
        }
        return clonedObject;
      }
      return item;  // Return primitive types unchanged
    });
  }
}
