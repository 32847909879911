import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MONITOR_STATUSES, ProcessStatus } from '../../../../models/process-status';
import { ProcessStatusFacade } from '../process-status.facade';
import { Observer, Subscription } from 'rxjs';
import { State, Status } from '../../../../models/status';
import { ProcessService } from '../../../../../services/process.service';
import { MainFacade } from '../../../../main.facade';
import { TreeNode } from 'primeng/api';
import { IFileInfo } from '../../../../models/file-info';

@Component({
  selector: 'process-status-item',
  templateUrl: './process-status-item.component.html',
  styleUrls: ['./process-status-item.component.scss']
})
export class ProcessStatusItemComponent implements OnInit, OnDestroy {
  protected readonly State = State;
  monitorStatuses = MONITOR_STATUSES;

  resultsInfo!: string;
  resultsTooltip!: string;
  state!: string;
  percentComplete = 0.0;
  message!: string;

  selectedNodeSubscription!: Subscription;
  parentNode!: TreeNode<IFileInfo> | undefined;

  subscription$!: Subscription;

  @Input() processStatus!: ProcessStatus;

  constructor(private facade: ProcessStatusFacade, private processService: ProcessService,
              private mainFacade: MainFacade) {}

  ngOnInit(): void {
    this.selectedNodeSubscription = this.mainFacade.getSelectedNode$()?.subscribe(parentNode => {
      this.parentNode = parentNode?.parent;
    });
    this.parseResults(this.processStatus.results);
    this.state = this.processStatus.state;
    this.percentComplete = this.getPercentComplete(this.processStatus.complete);
    this.message = this.processStatus.message;
    if (MONITOR_STATUSES.includes(this.processStatus.state)) {
      this.startMonitor();
    }
  }

  ngOnDestroy() {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
    if (this.selectedNodeSubscription) {
      this.selectedNodeSubscription.unsubscribe();
    }
  }

  /**
   * Subscribe to the service monitor for the process
   *
   * @private
   */
  private startMonitor() {
    this.subscription$ = this.processService.monitor(
      this.processService.createMonitorUrl(this.processStatus.workflowId),
      this.createSubmitObserver(this.processStatus.workflowId));
  }

  public createSubmitObserver(workflowId: string) : Observer<Status> {
    return {
      next: (status: Status) => {
        if (status) {
          this.updateStatus(status);
        } else {
          console.error('No status available for observer to process');
        }
      },
      error: error => {
        console.error(`Error in submit observer: ${error}`);
      },
      complete: () => {
        // ok
      }
    }
  }

  private updateStatus(status: Status) {
    if (status) {
      this.parseResults(status.results);
      this.state = (status.state) ? status.state : State.NONE;
      this.percentComplete = this.getPercentComplete(status.complete);
      this.message = (status.message) ? status.message : '';
      this.manageState();
    }
  }

  /**
   * If the state is COMPLETE or ERROR, show an applicable message and unsubscribe from the monitor
   *
   * @private
   */
  private manageState() {
    if (this.state === State.COMPLETE) {
      const summary = 'Process Complete';
      // Message should not say files generated,
      // not all processes generate files.
      const content = `${this.message}.`;
      this.facade.successMessage(summary, content);
      // update parent node
      // TODO this node update refreshed the main diagram as well and it shouldn't
      // this is commented out until the diagram refresh is fixed. See CIMG-375
      // this.mainFacade.updateNode(this.parentNode);
      this.subscription$.unsubscribe();

    } else if (this.state == State.ERROR) {
      const summary = `Process Error - ${this.processStatus.info}`;
      const content = this.message.length > 75 ? `Please contact support. ${this.processStatus.workflowId}` : `${this.message}`;
      this.facade.errorMessage(summary, content);
      this.subscription$.unsubscribe();
    }
  }

  /**
   * Extract relevant information from the results field of the Status response object
   *
   * @param results
   * @private
   */
  private parseResults(results: string[] | null | undefined) {
    if (results && results.length > 0) {
      // when there are multiple files for now just show the information on the first one
      // remove the scheme 'gs://'
      const pathWithoutScheme = results[0].substring(5, results[0].length);
      // get start position of subdirectory which starts after the bucket
      const subdirectoryIndex = pathWithoutScheme.indexOf('/');
      // get the subdirectory substring
      const subdirectory = pathWithoutScheme.substring(subdirectoryIndex, pathWithoutScheme.length);
      this.resultsInfo = subdirectory;
      this.resultsTooltip = results[0];
    } else {
      this.resultsInfo = 'NONE';
      this.resultsTooltip = '';
    }
  }

  public showTerminate(): boolean {
    return this.state === State.RUNNING || this.state === State.SUBMITTED;
  }

  private getPercentComplete(complete: number | undefined) {
    if (complete) {
      return Math.round(complete * 100.0); // status has it as a fraction.
    } else {
      return 0.0;
    }
  }

  public onClickTerminate() {
    this.facade.terminateProcess(this.processStatus.workflowId);
  }

  public trimBucket(file: string | null) {
    if (file){
      return file.split(/^gs:\/\/[^/]+\/(.*)/).filter(n => n)[0];
    }
    return '';
  }

  public trimBucketAndFolder(file: string | null) {
    if (file){
      return this.trimBucket(file).split(/^(.*)\/([^/]*)$/).filter(n => n)[0];
    }
    return '';
  }

  public determineIconFromState(): string {
    if (this.state === State.SUBMITTED){
      return 'pi pi-cloud-upload'
    } else if (this.state === State.RUNNING){
      return 'pi pi-spin pi-spinner'
    } else if (this.state === State.COMPLETE) {
      return 'pi pi-check-circle'
    } else if (this.state === State.ERROR) {
      return 'pi pi-exclamation-circle'
    } else if (this.state == State.CANCELLED) {
      return 'pi pi-times-circle'
    } else {
      return 'pi pi-question-circle'
    }
  }

  public tooltipContent() {
    if (this.state === State.ERROR) {
      return `${this.state}\n\n${this.processStatus.workflowId}`
    }
    return this.state;
  }
}
