<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Inputs
    </div>
  </p-divider>

  <div class="field grid">
    <label for="modelFile" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Model
      <i class="pi pi-info-circle px-1"
        pTooltip="Selected classification model"
        tooltipPosition="top"
      ></i>
    </label>
    <input type="hidden" formControlName="modelFile">
    <div class="col-12 md:col-9">
      <div class="static-model-display p-inputtext-sm w-full">
        {{ form.controls['modelFile'].value }}
      </div>
    </div>
</div>

  <div class="field grid">
    <label for="imageDirectory" class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Images
      <i class="pi pi-info-circle px-1"
        pTooltip="Select directory of images"
        tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        formControlName="imageDirectory"
        placeholder="SELECT DIRECTORY"
        [options]="imageDirectories"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <!-- this section becomes visible when file gets selected -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <!-- Tissue Mask -->
    <div class="field grid h-3rem align-items-center">
      <label for="tissueMask" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Tissue Masks:
      </label>
      <div class="col-12 md:col-7">
        <ng-container *ngIf="form.get('editTissueMask')?.value; else viewTissueMask">
          <input id="tissueMask" type="text" class="p-inputtext-sm w-full"
                 formControlName="tissueMaskPath" pInputText/>
          <i class="pi pi-times icon-hover" (click)="form.get('editTissueMask')?.setValue(false)"></i>
        </ng-container>
        <ng-template #viewTissueMask>
          {{form.get('tissueMaskPath')?.value}}
          <i class="pi pi-pencil icon-hover" (click)="form.get('editTissueMask')?.setValue(true)"></i>
        </ng-template>
      </div>
    </div>

    <!-- Damage Score -->
    <div class="field grid h-3rem align-items-center">
      <label for="damageScore" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Damage Scores:
      </label>
      <div class="col-12 md:col-7">
        <ng-container *ngIf="form.get('editDamageScore')?.value; else viewDamageScore">
          <input id="damageScore" type="text" class="p-inputtext-sm w-full"
                 formControlName="damageScorePath" pInputText/>
          <i class="pi pi-times icon-hover" (click)="form.get('editDamageScore')?.setValue(false)"></i>
        </ng-container>
        <ng-template #viewDamageScore>
          {{form.get('damageScorePath')?.value}}
          <i class="pi pi-pencil icon-hover" (click)="form.get('editDamageScore')?.setValue(true)"></i>
        </ng-template>
      </div>
    </div>

    <!-- Heatmap -->
    <div class="field grid h-3rem align-items-center">
      <label for="heatmap" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Heatmaps:
      </label>
      <div class="col-12 md:col-7">
        <ng-container *ngIf="form.get('editHeatmap')?.value; else viewHeatmap">
          <input id="heatmap" type="text" class="p-inputtext-sm w-full"
                 formControlName="heatmapPath" pInputText/>
          <i class="pi pi-times icon-hover" (click)="form.get('editHeatmap')?.setValue(false)"></i>
        </ng-container>
        <ng-template #viewHeatmap>
          {{form.get('heatmapPath')?.value}}
          <i class="pi pi-pencil icon-hover" (click)="form.get('editHeatmap')?.setValue(true)"></i>
        </ng-template>
      </div>
    </div>

    <div class="pt-3 pb-3 process-buttons">
      <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
      <p-button
        type="submit" icon="pi pi-play"
        tooltipPosition="top"
        label="Run"
        styleClass="p-button-primary">
      </p-button>
    </div>

  </ng-container>
</form>
