<div class="file-tree">
  <p-tree
    [ngClass]="{'populated' : nodes.length > 0}"
    id="tree"
    [value]="nodes"
    scrollHeight="100%"
    (onNodeExpand)="onNodeExpand($event)"
    (onNodeCollapse)="onNodeCollapse($event)"
    [loading]="loadingTree && nodes.length === 0"
    selectionMode="single"
    [(selection)]="selectedNode"
    (onNodeSelect)="onNodeSelect($event)"
    (onNodeUnselect)="onNodeSelect($event)"
    (onNodeContextMenuSelect)="onNodeSelect($event)"
    [contextMenu]="treemenu"
  >
    <ng-template let-node pTemplate="default">
      {{ node.label }}
      <span *ngIf="showFolderLoader(node)">
        <p-progressSpinner styleClass="loading-node" strokeWidth="6"></p-progressSpinner>
      </span>
      <div *ngIf="node.data | instanceof: IFile as file">
        <span class="text-xs font-italic text-primary">
          {{ node.data.size | filesize }}
        </span>
      </div>
    </ng-template>
  </p-tree>
</div>

<!--<span *ngIf="download$ | async as download">-->
<!--  <span *ngIf="download.progress !== 100">-->
<!--        <p-progressBar [mode]="'indeterminate'" [value]="download.progress"></p-progressBar>-->
<!--  </span>-->
<!--</span>-->

<p-contextMenu #treemenu [model]="treeMenu"></p-contextMenu>
