
export interface ProcessStatus {
  workflowId: string,
  userName: string,
  state: string,
  message: string,
  complete: number,
  info: string, // process name
  errorStack: string | null,
  submissionInput: string | null,
  results: string[] | null,
  action: string,
  warnings: string[] | null,
  regionImages: string[] | null,
}

export const MONITOR_STATUSES = ['RUNNING', 'SUBMITTED'];
