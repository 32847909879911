import { Component } from '@angular/core';

import { SortEvent } from 'primeng/api';

import { MainFacade } from '../../main.facade';
import { TableData } from '../../models/table-data';

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent {

  tableData: TableData = new TableData();
  data: string[] = [];
  isLoading = false;

  constructor(private mainFacade: MainFacade) {
  }

  ngOnInit(): void {
    this.mainFacade.getTableData$().subscribe({
      next: (data: TableData) => {
        if (data !== null) {
          this.tableData = data;
          this.data = this.tableData.data;
        }
      }
    });
    this.mainFacade.isFileLoading$().subscribe({
      next: (isLoading) => {
        this.isLoading = isLoading;
      }
    })
  }

  alphaNumericSort(event: SortEvent) {
    if (event) {
      event.data?.sort((data1, data2) => {
        const fieldName = <string>event.field;
        const value1 = data1[fieldName];
        const value2 = data2[fieldName];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2, 'en', { numeric: true });
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        const order = <number>event.order;
        return (order * result);
      });
    }
  }

}
