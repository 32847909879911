import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { ResultsSummary } from '../../models/results';

@Injectable({
  providedIn: 'root',
})
export class ResultsSummaryState {

  private isResultsSummaryLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isChildrenLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isImageLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private resultsSummary$: BehaviorSubject<ResultsSummary> =
    new BehaviorSubject<ResultsSummary>(new ResultsSummary());
  private results$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private summaryImageUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private summaryConfigUrl$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  public getIsResultsSummaryLoading() {
    return this.isResultsSummaryLoading$.asObservable();
  }
  public setIsResultsSummaryLoading(isLoading: boolean) {
    this.isResultsSummaryLoading$.next(isLoading);
  }

  public getIsImageLoading$() {
    return this.isImageLoading$.asObservable();
  }

  public setIsImageLoading(isLoading: boolean) {
    this.isImageLoading$.next(isLoading);
  }

  public getIsChildrenLoading$() {
    return this.isChildrenLoading$.asObservable();
  }

  public setIsChildrenLoading(isLoading: boolean) {
    this.isChildrenLoading$.next(isLoading);
  }

  public getResultsSummary$() {
    return this.resultsSummary$.asObservable();
  }

  public setResultsSummary(resultsSummary: ResultsSummary) {
    this.resultsSummary$.next(resultsSummary);
  }

  public getResults$() {
    return this.results$.asObservable();
  }

  public setResults(results: any[]) {
    this.results$.next(results);
  }

  public getSummaryImageUrl$(): Observable<string | null> {
    return this.summaryImageUrl$.asObservable();
  }

  public setSummaryImageUrl(url: string | null) {
    this.summaryImageUrl$.next(url);
  }

  public getSummaryConfigUrl$(): Observable<string | null> {
    return this.summaryConfigUrl$.asObservable();
  }

  public setSummaryConfigUrl(url: string | null) {
    this.summaryConfigUrl$.next(url);
  }

}
