<div class="card">
  <div class="card-container">
    <p-galleria
      [value]="images"
      [responsiveOptions]="responsiveOptions"
      [numVisible]="4"
      class="w-full"
      [showIndicators]="true"
      [showThumbnails]="false"
      [autoPlay]="true"
      [circular]="true"
    >
      <ng-template pTemplate="item" let-item>
        <img [src]="item.itemImageSrc" class="w-full"/>
      </ng-template>
    </p-galleria>

    <div class="flex flex-row justify-content-evenly">

      <div class="flex-1 flex px-4">
        <div>
          <i class="pi pi-question p-3 border-solid jax-dark-cerulean-border"></i>
        </div>

        <div class="p-3 line-height-3">
          <span class="text-lg font-semibold jax-dark-cerulean">
            WHAT IS JAX IMAGE TOOLS:
          </span>

          <p>
            A cloud-native application that serves as a platform to
            develop and deploy both conventional and ML/AI image
            analysis workflows.
            <br>
            It is developed in the Google Cloud Platform
            (GCP) with microservices and the workflows are run on Temporal.io.
            Users can perform the image analysis tasks in a web browser,
            which makes it <b>easier to learn</b> and <b>use</b>.
            <br><br>
            Get <b>started with</b> JAX Image Tools
            <a href="https://jacksonlaboratory.sharepoint.com/sites/Microscopy/SitePages/Cloud-Image-Tools.aspx" target="_blank" class="no-underline">here</a>
            and <b>learn how to use</b> JAX Images Tools
            <a href="https://jacksonlaboratory.sharepoint.com/:p:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7BE44F9388-729C-47B6-ACC2-196F89D71495%7D&file=How-to-use-JAX-image-tools.pptx&action=edit&mobileredirect=true" target="_blank" class="no-underline">here</a>.
          </p>
        </div>
      </div>

      <div class="flex-1 flex px-4">
        <div>
          <i class="pi pi-th-large p-3 border-solid jax-dark-cerulean-border"></i>
        </div>

        <div class="p-3">
          <span class="text-lg font-semibold jax-dark-cerulean">
            JAX IMAGE TOOLS WORKFLOWS:
          </span>
          <ul class="text-lg line-height-4">
            <li>
              <span class="font-medium">Slide Cropper</span>
              (<a href="https://jacksonlaboratory.sharepoint.com/:w:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7BB2C8AF07-76F9-4D1D-8F6E-8A323FD380B4%7D&file=Slide-Cropper-use-cases_V2.docx&action=default&mobileredirect=true&cid=af56d543-dab9-4f2a-861a-0ab185635b26" class="no-underline" target="_blank">Use Case</a>)
            </li>
            <li>
              <span class="font-medium">Image Alignment</span>
              (<a href="https://jacksonlaboratory.sharepoint.com/:w:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7B8F50C20D-94FD-4DD8-8343-FABF2C322F79%7D&file=Image-Alignment-use-cases.docx&action=default&mobileredirect=true&cid=677c2006-086c-4a45-80ed-3898389906e0" class="no-underline" target="_blank">Use Case</a>)
            </li>
            <li>
              <span class="font-medium">Fibers and Nuclei</span>
              (<a href="https://jacksonlaboratory.sharepoint.com/:w:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7B1C64069E-5490-4362-B472-5D617DF42654%7D&file=Fibers-and-Nuclei-use-cases_V2.docx&action=default&mobileredirect=true&cid=9ce9ed29-bc4f-457b-94b9-9829cd2c7f87" class="no-underline" target="_blank">Use Case</a>)
            </li>
            <li>
              <span class="font-medium">Extended Field of Depth</span>
              (<a href="https://jacksonlaboratory.sharepoint.com/:w:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7B1808ECC6-CA63-4AF0-92DB-549C407990BA%7D&file=Extended-Depth-of-Field-Use-Cases-V1.docx&action=default&mobileredirect=true&cid=7531f0f1-9409-4446-afcd-072a37fe8b9c" class="no-underline" target="_blank">Use Case</a>)
            </li>
            <li>
              <span class="font-medium">Kidney Classifier</span>
              (<a href="https://jacksonlaboratory.sharepoint.com/:w:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7B90A68B46-CCA6-4137-9732-19603C87179C%7D&file=Kidney-Classifier-use-cases_V1.docx&action=default&mobileredirect=true&wdOrigin=TEAMS-MAGLEV.p2p_ns.rwc&wdExp=TEAMS-TREATMENT&wdhostclicktime=1724075060669&web=1" class="no-underline" target="_blank">Use Case</a>)
            </li>
          </ul>
        </div>
      </div>

      <div class="flex-1 flex px-4">
        <div>
          <i class="pi pi-envelope p-3 border-solid jax-dark-cerulean-border"></i>
        </div>

        <div class="p-3 line-height-3">
          <span class="text-lg font-semibold jax-dark-cerulean">
            CONTACT:
          </span>
          <br><br>
          <span class="font-semibold">General</span>: Sonia Erattupuzha, Ph.D.
          (<a class="no-underline " href="mailto:sonia.erattupuzha@jax.org">email</a>);
          <br>
          Philipp Henrich, Ph.D.
          (<a href="mailto:philipp.henrich@jax.org" class="no-underline">email</a>)
          <br><br>
          <span class="font-semibold">Bugs &amp; Technical Support</span>:
          JAX Image Tools Support
          (<a class="no-underline" href="mailto:georgi.kolishovski@jax.org">email</a>)
        </div>
      </div>
    </div>
  </div>
</div>
