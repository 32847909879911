import { Injectable } from '@angular/core';
import { TreeNode, MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';

import { DisplayType, IFileInfo, FileProject, ImageInfo } from './models/file-info';
import { Status } from './models/status';
import { TableData } from './models/table-data';

@Injectable({
  providedIn: 'root',
})
export class MainState {
  // layout states
  private selectedTabItemL$ = new BehaviorSubject<MenuItem | null>(null);
  private selectedTabItemR$ = new BehaviorSubject<MenuItem | null>(null);
  private mainTabMenuItems$ = new BehaviorSubject<MenuItem[]>([]);

  private projects$ = new BehaviorSubject<FileProject[]>([]);
  private selectedProject$ = new BehaviorSubject<FileProject | null>(null);
  private fileInfo$ = new BehaviorSubject<IFileInfo[]>([]);
  private selectedNode$ = new BehaviorSubject<TreeNode<IFileInfo> | null>(null);
  private selectedFile$ = new BehaviorSubject<IFileInfo | null>(null);
  private previewUrl$ = new BehaviorSubject<string | null>(null);
  private metadataUrl$ = new BehaviorSubject<string | null>(null);
  private fileMetadata$ = new BehaviorSubject<any | null>(null);
  private previewSlicesUrls$ = new BehaviorSubject<string[] | undefined>(undefined);
  private zoomRegion$ = new BehaviorSubject<any[] | null>(null);
  private imageSize$ = new BehaviorSubject<any>(null);
  private fileContent$ = new BehaviorSubject<string | null>(null);
  private tableData$ = new BehaviorSubject<TableData>(new TableData());
  private fileTreeLoading$ = new BehaviorSubject<boolean>(false);
  private fileLoading$ = new BehaviorSubject<boolean>(false);
  private imageLoading$ = new BehaviorSubject<boolean>(false);
  // this should be a subject in order not to hold any value and just pass the subject change
  private imageInfo$ = new Subject<ImageInfo>();
  // [ GIK 1/18/23] Change this to string
  private loadingError$ = new BehaviorSubject<boolean>(false);
  private processing$ = new BehaviorSubject<boolean>(false);
  private processingStatus$ = new BehaviorSubject<Status | null>(null);
  private processingMessage$ = new BehaviorSubject<any>(null);
  private validationMessage$ = new Subject<string | null>();
  // this should be a subject in order not to hold any value and just pass the subject change
  private setPanelWidth$ = new Subject<number>();
  private imageLoadingMessage$ = new BehaviorSubject<string>('Loading image...');
  private imageCached$ = new BehaviorSubject<boolean>(false);
  private zoom$ = new Subject<boolean>();
  private filename$ = new BehaviorSubject<string | undefined>(undefined);

  // temporary hook to the data...this probably should be managed differently
  private diagramData$ = new BehaviorSubject<any>(null);

  private rootNodes = new BehaviorSubject<TreeNode<IFileInfo>[]>([]);

  private fileInfoZoomSession!: string;

  constructor() {
    // do nothing
  }

  getSelectedTabMenuItemL$() {
    return this.selectedTabItemL$.asObservable();
  }

  setSelectedTabItemL$(selectedTab: MenuItem): void {
    this.selectedTabItemL$.next(selectedTab);
  }

  getSelectedTabMenuItemR$() {
    return this.selectedTabItemR$.asObservable();
  }

  setSelectedTabItemR$(selectedTab: MenuItem): void {
    this.selectedTabItemR$.next(selectedTab);
  }

  setTabMenuItems$(items: MenuItem[]) {
    this.mainTabMenuItems$.next(items);
  }

  getMainTabMenu$() {
    return this.mainTabMenuItems$.asObservable();
  }

  getProjects$() {
    return this.projects$.asObservable();
  }

  setProjects$(projects: FileProject[]) {
    this.projects$.next(projects);
  }

  getSelectedProject$() {
    return this.selectedProject$.asObservable();
  }

  setSelectedProject$(project: IFileInfo) {
    this.selectedProject$.next(project);
  }

  getFileInfo$() {
    return this.fileInfo$.asObservable();
  }

  setFileInfo$(nodes: IFileInfo[]) {
    this.fileInfo$.next(nodes);
  }

  getSelectedNode$() {
    return this.selectedNode$.asObservable();
  }

  setSelectedNode(selected: TreeNode<IFileInfo> | null): void {
    this.selectedNode$.next(selected);
  }

  getSelectedFile$() {
    return this.selectedFile$.asObservable();
  }

  setSelectedFile(selected: IFileInfo | undefined): void {
    if (selected == undefined) {
      this.selectedFile$.next(null);
    } else {
      this.selectedFile$.next(selected);
    }
  }

  getPreviewUrl$() {
    return this.previewUrl$.asObservable();
  }

  setPreviewUrl(url: string | null) {
    this.previewUrl$.next(url);
  }

  getMetadataUrl$() {
    return this.metadataUrl$.asObservable();
  }

  setMetadataUrl(url: string | null) {
    this.metadataUrl$.next(url);
  }

  getFileMetadata$() {
    return this.fileMetadata$.asObservable();
  }

  setFileMetadata$(metadata: any) {
    this.fileMetadata$.next(metadata);
  }

  setPreviewSlicesUrls$(urls: string[] | any) {
    this.previewSlicesUrls$.next(urls);
  }
  getPreviewSlicesUrls$() {
    return this.previewSlicesUrls$.asObservable();
  }

  setZoomRegion$(zoomRegionData: any[]) {
    this.zoomRegion$.next(zoomRegionData);
  }

  getZoomRegion$() {
    return this.zoomRegion$.asObservable();
  }

  getImageSize$() {
    return this.imageSize$.asObservable();
  }

  setImageSize$(size: any) {
    this.imageSize$.next(size);
  }

  getImageInfo$() {
    return this.imageInfo$.asObservable();
  }

  setImageInfo$(imgInfo: ImageInfo) {
    this.imageInfo$.next(imgInfo);
  }

  getFileContent$() {
    return this.fileContent$.asObservable();
  }

  setFileContent(content: string | null): void {
    this.fileContent$.next(content);
  }

  getTableData$() {
    return this.tableData$.asObservable();
  }

  setTableData(tableData: TableData): void {
    this.tableData$.next(tableData);
  }

  isFileTreeLoading$() {
    return this.fileTreeLoading$.asObservable();
  }

  setFileTreeLoading(isLoading: boolean): void {
    this.fileTreeLoading$.next(isLoading);
  }

  isFileLoading$() {
    return this.fileLoading$.asObservable();
  }

  setFileLoading(isLoading: boolean): void {
    this.fileLoading$.next(isLoading);
  }

  isImageLoading$() {
    return this.imageLoading$.asObservable();
  }

  setImageLoading(isLoading: boolean) {
    this.imageLoading$.next(isLoading);
  }

  setImageLoadingMessage(imageLoadingMessage: string) {
    this.imageLoadingMessage$.next(imageLoadingMessage);
  }
  getImageLoadingMessage$(): Observable<string> {
    return this.imageLoadingMessage$.asObservable();
  }

  isLoadingError$() {
    return this.loadingError$.asObservable();
  }

  setLoadingError(isError: boolean) {
    this.loadingError$.next(isError);
  }

  isProcessing$() {
    return this.processing$.asObservable();
  }

  setProcessing(isProcessing: boolean) {
    this.processing$.next(isProcessing);
  }

  getProcessingStatus$() {
    return this.processingStatus$.asObservable();
  }

  setProcessingStatus(status: Status | null) {
    this.processingStatus$.next(status);
  }

  getProcessingMessage$() {
    return this.processingMessage$.asObservable();
  }

  setProcessingMessage(message: any) {
    this.processingMessage$.next(message);
  }

  getValidationMessage$() {
    return this.validationMessage$.asObservable();
  }

  setValidationMessage(message: string | null) {
    this.validationMessage$.next(message);
  }

  getDiagramData$() {
    return this.diagramData$.asObservable();
  }

  setDiagramData(data: any) {
    this.diagramData$.next(data);
  }

  getPanelWidth() {
    return this.setPanelWidth$.asObservable();
  }
  setPanelWidth(value: number) {
    this.setPanelWidth$.next(value);
  }

  getRootNodes$(): Observable<TreeNode<IFileInfo>[]> {
    return this.rootNodes.asObservable();
  }

  setRootNodes(nodes: TreeNode<IFileInfo>[]) {
    this.rootNodes.next(nodes);
  }

  isFileContent(): boolean {
    return (this.getFileContent() != null);
  }

  getFileContent(): string | null {
    let fileContent: string | null = null;
    const sub = this.getFileContent$().pipe(take(1)).subscribe(content => fileContent = content);
    sub.unsubscribe();
    return fileContent;

    // // TODO - return an observable instead
    // this.getFileContent$().pipe(switchMap(fileContent => {
    //   if (fileContent == null) {
    //     of(null);
    //   } else {
    //     of(fileContent);
    //   }
    // }));
  }

  public setFileInfoZoomSession(fileInfoZoomSession: string) {
    this.fileInfoZoomSession = fileInfoZoomSession;
  }

  public getFileInfoZoomSession() {
    return this.fileInfoZoomSession;
  }

  public isImageCached$() {
    return this.imageCached$.asObservable();
  }
  public setImageCached(imageCached: boolean) {
    this.imageCached$.next(imageCached);
  }

  /**
   * returns whether a zoom event is happening
   */
  public isZoom$() {
    return this.zoom$.asObservable();
  }
  public setZoom(zoom: boolean) {
    this.zoom$.next(zoom);
  }

  public getFilename() {
    return this.filename$.asObservable();
  }
  public setFilename(filename: string) {
    this.filename$.next(filename);
  }
}
