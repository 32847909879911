<div>
  <header></header>

  <p-progressBar *ngIf="auth.isLoading$ | async" mode="indeterminate"
                 [style]="{ height: '3px' }">
  </p-progressBar>
  <div id="container" *ngIf="(auth.isLoading$ | async) === false">
    <div id="page-content">
      <main></main>
    </div>
  </div>

  <footer class="w-full flex p-3 text-sm"></footer>
</div>

<p-confirmDialog></p-confirmDialog>

<p-toast key="app-toast"></p-toast>
