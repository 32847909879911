import { IFileInfo, FileProject, FileFolder, BasicFile, ImageFile, RawFile, RawFileInfo } from '../file-info';

export class FileInfoFactory {

  // TODO - convert from any to RawFileInfo as input
  static getFileInfo(jsonObj: any): IFileInfo {
    const fileType = jsonObj.type;
    if (fileType === 'Project') {
      return new FileProject(jsonObj);
    } else if (fileType === 'Folder') {
      return new FileFolder(jsonObj, jsonObj.resultsFolder);
    } else if (fileType === 'File') {
      if (jsonObj.supportedImage) {
        return new ImageFile(jsonObj, jsonObj.resultFile);
      } else {
        return new BasicFile(jsonObj, jsonObj.resultFile);
      }
    } else {
      // unknown file
      console.error(`Could not create IFileInfo from object data: ${JSON.stringify(jsonObj)}`);
      return new RawFile(jsonObj);
    }
  }

}
