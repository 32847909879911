import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MenuItem, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { Download } from '../../../download/download';

import { MainFacade } from '../../main.facade';
import { FileFolder, IFile, IFileInfo } from '../../models/file-info';

@Component({
  selector: 'file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileTreeComponent implements OnInit {
  // necessary to enable type checking within the component's template
  public readonly IFile = IFile;

  public selectedNode!: TreeNode | null;
  public nodeToLoad!: TreeNode | null;
  public loadingTree!: boolean;
  public loadingImage!: boolean;

  @Output() interuptPreview = new EventEmitter<null>();
  @Output() processSelected = new EventEmitter<null>();
  @Output() nodeExpand = new EventEmitter<TreeNode>();
  @Output() nodeSelect = new EventEmitter<TreeNode>();

  public treeMenu: MenuItem[] = [];
  public nodes: TreeNode<IFileInfo>[] = [];
  public download$: Observable<Download> | null = null;
  // protected readonly download = download;

  constructor(private facade: MainFacade,
              private confirmationService: ConfirmationService) {
    // do nothing
  }

  ngOnInit(): void {
    this.manageSubscriptions();
    this.treeMenu = this.createTreeMenu(null);
  }

  manageSubscriptions(): void {
    this.facade.getRootNodes$().subscribe(
      (rootNodes) => {
        this.nodes = rootNodes;
      }
    );
    this.facade.getSelectedNode$().subscribe({
      next: (node) => {
        this.selectedNode = node;
        this.treeMenu = this.createTreeMenu(node);
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.facade.isTreeLoading$().subscribe({
      next: (isLoading) => {
        this.loadingTree = isLoading;
      },
    });
  }

  createTreeMenu(node: TreeNode<IFileInfo> | null): MenuItem[] {
    return [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: (event) => this.onMenuOptionDelete(),
      },
      {
        label: 'Refresh',
        icon: 'pi pi-refresh',
        command: (event) => this.onMenuOptionRefresh(),
      },
      {
        label: 'Download',
        icon: 'pi pi-download',
        command: (event) => this.onMenuOptionDownload(event),
        disabled: !this.canDownload(node),
      }
    ];
  }

  canDownload(node: TreeNode<IFileInfo> | null): boolean {
    if (node === null || node.data === null) {
      return false;
    } else if (node.data instanceof IFile) {
      return true;
    }
    return false;
  }

  onMenuOptionDelete() {
    this.confirmationService.confirm({
      message: 'Please confirm that you would like to delete this file.',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.facade.deleteSelected();
      }
    });
  }

  onMenuOptionRefresh() {
    if (this.selectedNode) {
      if (this.selectedNode.children) {
        this.selectedNode.children.length = 0;
      }
    }
    this.nodeToLoad = this.selectedNode;
    this.facade.updateNode(this.nodeToLoad);
  }

  onMenuOptionDownload(event: any) {
    if (this.selectedNode) {
      const filePath = this.selectedNode.data.relPath;
      // build url
      const urlDownload = `https://storage.cloud.google.com/${this.selectedNode.data.project.bucket}/${filePath}`;
      // This is a simple way to trigger a href url download in the browser
      // The link should work as long as the user is logged-in in their session
      // See https://stackoverflow.com/a/49917066/1832062
      const a = document.createElement('a');
      a.href = urlDownload;
      const downloadName = urlDownload.split('/').pop();
      if (downloadName) {
        a.download = downloadName;
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  onNodeExpand(event: any): void {
    if (!this.loadingTree){
      this.nodeToLoad = event.node;
      this.nodeExpand.emit(event.node);
    } else {
      event.node.collapsed = true;
      event.node.expanded = false;
    }
  }

  onNodeCollapse(event: any): void {
    event.node.children.length = 0;
  }

  onNodeSelect(event: any): void {
    this.nodeSelect.emit(event.node);
  }

  isFolder(file: IFileInfo){
    return file instanceof FileFolder;
  }

  isSelectedNode(node: any){
    return node === this.selectedNode;
  }

  showFolderLoader(node: any){
    return this.isFolder(node.data) && this.loadingTree && this.isSelectedNode(node);
  }
}
