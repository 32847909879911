import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year = new Date().getFullYear();
  footerText = `JAX IMAGE TOOLS (${environment.version}), ©${this.year} THE JACKSON LABORATORY`;
}
