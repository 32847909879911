import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'edof',
  templateUrl: './edof.component.html',
  styleUrls: ['./edof.component.scss'],
})
export class EDOFComponent implements OnInit {

  form!: FormGroup;
  public selectedNode: TreeNode<IFileInfo> | null = null;

  public noiseFilterSizesOptions = [
    { label: '0', name: '0', value: 0 },
    { label: '3', name: '3', value: 3 },
    { label: '5', name: '5', value: 5 }
  ];
  public gradientKernelOptions = [
    { label: '1', name: '1', value: 1 },
    { label: '3', name: '3', value: 3 },
    { label: '5', name: '5', value: 5 },
    { label: '7', name: '7', value: 7 }
  ]

  isOutputFolderEditable = false;
  isOutputImageEditable = false;
  isOutputZMapEditable = false;
  validationMessage: string | null = null;

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        this.selectedNode = node;
      }
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  configForm() {
    if (!this.processFacade.getEdofFormGroup()) {
      const form = this.fb.group({
        input: [null, Validators.required],
        gradientKernel: [5, Validators.required],
        noiseFilter: [3, Validators.required],
        lowPass: [2.0, Validators.required],
        outputFolder: [''],
        outputImage: ['extended_depth.tif', Validators.required],
        outputZmap: ['zmap.tif', Validators.required]
      });
      this.processFacade.setEdofFormGroup(form);
    }
    this.form = <FormGroup>this.processFacade.getEdofFormGroup();
  }

  onSubmit() {
    if (this.selectedNode) {
      const selectedFile: IFileInfo | undefined = this.selectedNode.data;
      // eslint-disable-next-line
      this.form.controls['input'].setValue(selectedFile);
      const parent: TreeNode<IFileInfo> | undefined = this.selectedNode.parent;
      let bucket = undefined;
      let parentPath = undefined;
      if (selectedFile && parent && parent.data) {
        bucket = selectedFile.project.bucket;
        parentPath = parent.data.relPath;
      }
      const edofRequest =
        RequestFactory.getEdofRequestFromForm(this.form.value, bucket, parentPath);
      this.mainFacade.runProcess(edofRequest);
    }
  }

  onEditOutputFolderName(val: boolean) {
    this.isOutputFolderEditable = val;
  }

  /* sets output image input field's editability */
  onEditOutputImage(val: boolean) {
    this.isOutputImageEditable = val;
  }
  /* sets output zmap input field's editability */
  onEditOutputZMap(val: boolean) {
    this.isOutputZMapEditable = val;
  }
}
