import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { ProcessStatus } from '../../../models/process-status';
import { ProcessService } from '../../../../services/process.service';
import { UserFacade } from '../../../UserFacade';
import { User } from '@auth0/auth0-spa-js';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ProcessStatusFacade {

  user: User | null = null;

  private processStatuses: BehaviorSubject<ProcessStatus[]> = new BehaviorSubject<ProcessStatus[]>([]);

  constructor(private processService: ProcessService,
              private userFacade: UserFacade,
              private messageService: MessageService) {
    this.userFacade.getUser$().subscribe(
      (user) => {
        this.user = user;
      }
    );
  }

  public getProcessStatuses$(): Observable<ProcessStatus[]> {
    if (this.user?.sub) {
      const sub = <string>this.user.sub;
      return this.processService.getStatusByUserName(sub);
    } else {
      return of([]);
    }
  }

  public terminateProcess(workflowId: string) {
    if (workflowId) {
      this.processService.terminateProcess(workflowId).subscribe(
        () => {
          const summary = 'Terminate Success';
          const content = `The request to terminate process ${workflowId} was successful`
          this.successMessage(summary, content);
        },
        (error) => {
          const summary = 'Terminate Failed';
          const content = `The request to terminate process ${workflowId} failed`
          this.errorMessage(summary, content);
        }
      );
    }
  }

  public successMessage(title: string, content: string, sticky = false ) {
    this.showMessage(title, content, 'success', sticky);
  }

  public errorMessage(title: string, content: string, sticky = true) {
    this.showMessage(title, content, 'error', sticky);
  }

  private showMessage(title: string, content: string, severity: string, sticky: boolean) {
    this.messageService.add({ key: 'app-toast', severity:severity, summary: title, detail: content, sticky: sticky });
  }

}
