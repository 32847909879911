import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  images: any[] = [];

  responsiveOptions: any[] = [];

  constructor() {

    this.images = [
            {
              itemImageSrc: '../assets/2023_Imaging_v4_1.png',
              thumbnailImageSrc: '../assets/2023_Imaging_v4_1.png',
              alt: 'JAX Image Tools Image Alignemnt',
              title: 'JAX Image Tools Image Alignment'
            },
            {
              itemImageSrc: '../assets/2023_Imaging_v4_2.png',
              thumbnailImageSrc: '../assets/2023_Imaging_v4_2.png',
              alt: 'JAX Image Tools Slide Cropper',
              title: 'JAX Image Tools Slide Cropper'
            },
            {
              itemImageSrc: '../assets/2023_Imaging_v4_3.png',
              thumbnailImageSrc: '../assets/2023_Imaging_v4_3.png',
              alt: 'JAX Image Tools Extended Field of Depth',
              title: 'JAX Image Tools Extended Field of Depth'
            },
            {
              itemImageSrc: '../assets/2023_Imaging_v4_4.png',
              thumbnailImageSrc: '../assets/2023_Imaging_v4_4.png',
              alt: 'JAX Image Tools Fiber and Nuclei',
              title: 'JAX Image Tools Fiber and Nuclei'
            }
        ];

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
  }

}
