import { Component, OnDestroy, OnInit } from '@angular/core';

import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';
import { ProcessType } from '../../../models/request';
import { Observer, Subscription } from 'rxjs';
import { SegmentationStatus, Status, State } from '../../../models/status';
import { PlotFacade } from '../../diagram/plot.facade';

enum CropRegionsAs { Rectangle = 'rectangle', Polygon = 'polygon' }

@Component({
  selector: 'segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.scss'],
})
export class SegmentationComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  validationMessage: string | null = null;

  regionUpdateSubscription!: Subscription;

  constructor(
    private mainFacade: MainFacade,
    private plotFacade: PlotFacade,
    private processFacade: ProcessFacade,
    private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
    this.regionUpdateSubscription = this.plotFacade.getRegionUpdate()?.subscribe(regions => {
      if (regions) {
        this.form.get('regionSettings')?.get('findRegions')?.setValue(false);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.regionUpdateSubscription) {
      this.regionUpdateSubscription.unsubscribe();
    }
  }

  configForm() {
    if (!this.processFacade.getSegmentationFormGroup()) {
      const form = this.fb.nonNullable.group({
        regionSettings: this.fb.nonNullable.group({
          findRegions: [true, Validators.required],
          cropFoundRegionsAsRectangles: [true, Validators.required],
          margin: [10, Validators.required],
          generatePreviews: [true, Validators.required],
          zip: [true, Validators.required],
          cropAsGreyScale: [false, Validators.required],
          forceSquare: [false, Validators.required],
        }),
        cropResolution: [0, Validators.required],
        tileConfiguration: this.fb.nonNullable.group({
          alwaysTile: [false, Validators.required],
          tileSize: this.fb.array([4096, 4096], []),
        })
      });
      this.processFacade.setSegmentationFormGroup(form);
    }
    this.form = <FormGroup>this.processFacade.getSegmentationFormGroup();
  }

  find(): void {
    let findRequest = RequestFactory.getSegmentationRequestFromForm(this.form.value);

    // Not sure if can change properties in this without changing
    // UI. To be on safe side, we deep clone here.
    findRequest = JSON.parse(JSON.stringify(findRequest));
    findRequest.regionSettings.manualRegions = [];
    findRequest.regionSettings.findRegions = true;
    findRequest.regionSettings.cropFoundRegionsAsRectangles = true;
    findRequest.cropResolution = 0;

    // Override the process to always find.
    findRequest.processType = ProcessType.FIND;
    // We run the find like we would a process.
    this.mainFacade.find(findRequest, this.createStatusObserver());
  }

  createStatusObserver() : Observer<Status> {
    return {
      next: (status: Status) => {
        if (status.state === State.COMPLETE) {
          const seg: SegmentationStatus = status as SegmentationStatus;
          if (seg.regions != undefined) {
            this.plotFacade.setRegions(seg.regions, this.plotFacade.getShowShapeLabel(), true);
          }
		}
      },
      error: error => {
        console.error(`Error in submit observer: ${error}`);
      },
      complete: () => {
        // ok
      }
    }
  }

  onSubmit(): void {
    const cropRequest = RequestFactory.getSegmentationRequestFromForm(this.form.value);
    this.mainFacade.runProcess(cropRequest);
  }

}
