import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';

import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { FileFolder, IFile, IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'sift',
  templateUrl: './sift.component.html',
  styleUrls: ['./sift.component.scss'],
})
export class SiftComponent implements OnInit {

  form!: FormGroup;
  public bucket: string | undefined = undefined;
  public parentFolderPath: string | undefined = undefined;
  validationMessage: string | null = null;

  // apply matrix transformation to align/SHG image or the reference
  matrixTransforms: any[] = [
    { name: 'Apply the transformation matrix to the Alignment image', key: 'A', value: true,
      info: `The alignment image will be transformed to the dimension of the reference image
             Choose this option if the alignment image is from a bright field slide scanner
             (NanoZoomer).` },
    { name: 'Apply the transformation matrix to the SHG/FL image', key: 'B', value: false,
      info: `The SHG/FL image will be transformed to the dimension of the reference image.
             Choose this option if the alignment image is taken together with the SHG/FL image.` }
  ];

  public children: IFileInfo[] = [];

  // handles input field display
  isOutputFolderFiledEditable = false;
  isTranslatedOutputEditable = false;
  isOverlayedResultEditable = false;
  isResizedNzEditable = false;
  isResizedSfEditable = false;

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          this.setFileDropdownData(node);
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  setFileDropdownData(node: TreeNode<IFileInfo>) {
    this.children = []
    if (node) {
      const selectedFile: IFileInfo | undefined = node.data;
      if (selectedFile) {
        if (selectedFile instanceof FileFolder) {
          this.setChildrenFromParent(node);
        } else {
          this.setChildrenFromParent(node.parent);
        }
      }
    }
  }

  setChildrenFromParent(node: TreeNode<IFileInfo> | undefined) {
    if (node && node.children) {
      node.children.forEach(child => {
        if (child.data) {
          this.children.push(child.data);
        }
      });
    }
  }

  configForm() {
    if(!this.processFacade.getSiftFormGroup()) {
      const form = this.fb.group({
        alignFile: [null, Validators.required],
        referenceFile: [null, Validators.required],
        shgFile: [null, Validators.required],
        transform: [ true, Validators.required],
        flip: [ true, Validators.required],
        downsample: [ false, Validators.required ],
        gamma: [ 1, [Validators.min(0), Validators.max(100)]],
        outputFolder: [''],
        siftOutputName: ['translated.tif', Validators.required],
        overlayedResultName: ['overlayed.tif', Validators.required],
        resizedNzName: ['resized_NZ.tif'],
        resizedSfName: ['resized_SF.tif']
      });
      this.processFacade.setSiftFormGroup(form);
    }
    this.form = <FormGroup>this.processFacade.getSiftFormGroup();
  }

  onSubmit() {
    const request = this.form.value;
    const bucket = request.alignFile.project.bucket;
    const parentPath = this.buildPath(request.alignFile);
    const siftRequest = RequestFactory.getSiftRequestFromForm(this.form.value, bucket, parentPath);
    console.log(siftRequest);
    this.mainFacade.runProcess(siftRequest);
  }

  buildPath(file: IFile): string {
    const index = file.relPath.indexOf(file.name);
    return file.relPath.substring(0, index);
  }

  /* sets 'output folder' input field's editability */
  onEditOutputFolderName(val: boolean) {
    this.isOutputFolderFiledEditable = val;
  }

  /* sets translated output input field's editability */
  onEditTranslatedOutputName(val: boolean) {
    this.isTranslatedOutputEditable = val;
  }

  /* sets overlayed result input field's editability */
  onEditOverlayedResultName(val: boolean) {
    this.isOverlayedResultEditable = val;
  }

  onEditResizedNzName(val: boolean) {
    this.isResizedNzEditable = val;
  }

  onEditResizedSfName(val: boolean) {
    this.isResizedSfEditable = val;
  }
}
