<div class="card">
  <div class="flex flex-column card-container p-2">
    <!-- display image files properties -->
    <ng-container *ngIf="fileInfo | instanceof: ImageFile as file">

      <p-divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-folder mr-2"></i>
          Project
        </div>
      </p-divider>

      <div class="flex align-items-center p-3">
        <b>Name</b>: {{ file.project.name }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Bucket</b>: {{ file.project.bucket }}
      </div>

      <p-divider align="left">
        <div class="inline-flex align-items-center">
          <i class="pi pi-image mr-2"></i>
          File
        </div>
      </p-divider>

      <div class="flex align-items-center p-3">
        <b>Name</b>: {{ file.name }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Type</b>: {{ file.type }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Path</b>: {{ file.relPath }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Results</b>: {{ file.results }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Size</b>: {{ file.size | filesize }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Width</b>: {{ file.width }}
      </div>
      <div class="flex align-items-center p-3">
        <b>Height</b>: {{ file.height }}
      </div>
    </ng-container>
  </div>
</div>
