<p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>Process Control</b>
    </div>
</p-divider>
<div>
<!--  <p-button icon="fa fa-play-circle"-->
<!--            tooltipPosition="top"-->
<!--            (click)="submit($event)"-->
<!--            label="Queue"-->
<!--            styleClass="p-button-primary"></p-button>-->
  <p-button icon="fa fa-play-circle"
            tooltipPosition="top"
            (click)="run($event)"
            label="Run"
            styleClass="p-button-secondary"></p-button>
  <!--
  <div [style.display]="isProcessing ? 'block' : 'none'">
    {{ status?.message }}
    <br/>
    <br/>
    <p-progressBar *ngIf="status as status" [value]="status.completePercent"></p-progressBar>
  </div>
  -->
</div>
