import { Injectable } from '@angular/core';

import * as Plotly from 'plotly.js-dist-min';
import { ZoomRequest } from '../main/models/request';
import { Polygon, Rectangle, Region } from '../main/models/plotting/region';
import { Buffer } from 'buffer';
import { DisplayType, ImageInfo } from '../main/models/file-info';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FilesService } from './files.service';
import { MainState } from '../main/main.state';
import { MessageService } from 'primeng/api';
import { ShapeSelection } from '../main/models/plotting/shape';
import { COLORMAP_OPTIONS, CONFIG, PlotUtilities } from '../main/plot.utilities';

const { Image } = require('image-js');

window.Buffer = Buffer;

@Injectable({
  providedIn: 'root'
})
export class PlotlyService {

  private shapes: any[] = [];
  private previousShapes: ShapeSelection[] = [];
  private isRegionSavedOn = true;
  private imageLength!: number;
  private screenHeight!: number;
  private plotDiv!: string;
  private isRealZoom = true;
  private scaleratio = true;
  private trueImgSize!: number[];
  private zoomCoordinates: number[] = [];
  private fileName!: string | undefined;
  private dragMode!: string;
  private showShapeLabel = false;
  private shapeColor = '#00FFFF';
  private labelColor = '#000000';
  private fillColor = '#ff00ff';
  private urls!: string[];
  imageInfo!: ImageInfo;
  private plotUtilities = new PlotUtilities();

  /** events */
  private onRelayoutEvent: any;

  private stackLoading$ = new BehaviorSubject<boolean>(false);
  private stackLoadingProgress$ = new BehaviorSubject<number>(0);
  private colormap$ = new BehaviorSubject(COLORMAP_OPTIONS[0]);
  private reverscale$ = new BehaviorSubject<boolean>(false);
  private scaleratio$ = new BehaviorSubject<boolean>(true);
  // current index of image in stack (if stack), 0 if single image
  private zIndex = new BehaviorSubject<number>(0);
  private autoscaleEvent = new Subject<any>();
  private regionUpdateEvent = new Subject<any[]>();
  private imageCached = false;
  private imageCachedSubscription: Subscription;
  private filenameSubscription: Subscription;

  constructor(fileService: FilesService, public mainState: MainState, public messageService: MessageService) {
    // initiaze relayout event
    this.onRelayoutEvent = (event: any) => { this.relayoutEventHandler(event, fileService, this, mainState) };

    this.imageCachedSubscription = this.mainState.isImageCached$().subscribe(imageCached => {
      this.imageCached = imageCached;
    });
    this.filenameSubscription = this.mainState.getFilename().subscribe(filename => {
      this.fileName = filename;
    });
  }

  /**
   * Load image
   * @param imageInfo
   * @param zIndex index of the image to load
   * @return an object with data and ratio keys
   */
  public async load(imageInfo: ImageInfo, zIndex: number) {
    const urls = imageInfo.urls;
    // use zIndex provided if any, 0 otherwise
    let imageUrl;
    if (zIndex) {
      imageUrl = urls[zIndex];
    } else {
      imageUrl = urls[0];
    }
    const isGrayscale = imageInfo.isGrayscale;
    const image = await Image.load(imageUrl);
    const xRatio = imageInfo.trueImageSize[0] / image.width;
    const yRatio = imageInfo.trueImageSize[1] / image.height;

    const trueImageSize = [];
    // [x0, x1, y0, y1]
    trueImageSize[0] = 0;
    trueImageSize[1] = imageInfo.trueImageSize[0];
    trueImageSize[2] = 0;
    trueImageSize[3] = imageInfo.trueImageSize[1];
    this.trueImgSize = trueImageSize;
    this.fileName = imageInfo.fileName;
    if (imageInfo.isStack && imageInfo.showStack) {
      const images: any[] = [];
      this.stackLoadingProgress$.next(0);
      for (let i = 0; i < urls.length - 1; i++) {
        console.log(`image stack ${i}`);
        // do not keep with loading if filename is different (a new file has been selected)
        // or if the stackLoading value is set to false
        if (this.fileName === imageInfo.fileName && this.stackLoading$.value) {
          const image = await Image.load(urls[i]);
          if (isGrayscale) {
            const grey = image.grey();
            const imgData = this.plotUtilities.arrayToMatrix(grey.data, image.width);
            images.push(imgData);
          } else {
            const rgbData = image.getPixelsArray();
            const rgbMatrix = this.plotUtilities.arrayToMatrix(rgbData, image.width);
            images.push(rgbMatrix);
          }
          this.stackLoadingProgress$.next(Math.round((i * 100) / urls.length));
        } else {
          // break stack loading
          break;
        }
      }
      // reset stackLoading progress to 0
      this.stackLoadingProgress$.next(0);
      return { data: images, ratios: [xRatio, yRatio],
               sizes: [image.width, image.height],
               filename: imageInfo.fileName };
    } else {
      let imageData;
      if (isGrayscale) {
        const grey = image.grey();
        imageData = this.plotUtilities.arrayToMatrix(grey.data, image.width);
      } else {
        const rgbData = image.getPixelsArray();
        imageData = this.plotUtilities.arrayToMatrix(rgbData, image.width);
      }
      return { data: [imageData],
        ratios: [xRatio, yRatio],
        sizes: [image.width, image.height],
        filename: imageInfo.fileName };
    }
  }

  /**
   *
   * @param plotDiv
   * @param imageLoaded object with data, ratios and sizes key
   * @param imageInfo ImageInfo object
   * @param screenHeight size of the available screen height
   */
  public plot(plotDiv: string, imageLoaded: any, imageInfo: ImageInfo, screenHeight: number) {
    const trueImageSize: number[] = [];
    this.zoomCoordinates = [];
    // [x0, x1, y0, y1]
    trueImageSize[0] = 0;
    trueImageSize[1] = imageInfo.trueImageSize[0];
    trueImageSize[2] = 0;
    trueImageSize[3] = imageInfo.trueImageSize[1];
    this.imageInfo = imageInfo;
    if (imageInfo.isGrayscale) {
      return this.plotHeatmap(plotDiv, imageInfo.urls, imageLoaded.data, trueImageSize,
        imageLoaded.ratios, screenHeight);
    } else {
      return this.plotRGBHeatmap(plotDiv, imageInfo.urls, imageLoaded.data, trueImageSize,
        imageLoaded.ratios, imageLoaded.sizes[0], imageLoaded.sizes[1], screenHeight);
    }
  }

  /**
   * Function used to plot a grayscale heatmap
   * @param plotDiv
   * @param urls
   * @param images
   * @param trueImgSize array containing the following : [x0, x1, y0, y1]
   * @param ratios
   * @param screenHeight
   * @return Promise true when plotting is finished
   */
  private plotHeatmap(plotDiv: string, urls: string[], images: any[], trueImgSize: number[],
                     ratios: number[], screenHeight: number): Promise<boolean> {
    // clean up memory
    Plotly.purge(plotDiv);
    this.plotDiv = plotDiv;
    this.imageLength = images.length;
    this.urls = urls;
    this.screenHeight = screenHeight;
    const traces: { z: any; type: string; name: string; visible: boolean; }[] = [];
    images.forEach((dataset, index) => {
      const trace = {
        x0: trueImgSize[0],
        dx: ratios[0],
        y0: trueImgSize[2],
        dy: ratios[0],
        z: dataset,
        type: 'heatmap',
        // We remove the tooltip annotation for performances reasons
        // text: dataset.map((row: any[], i: any) => row.map((item, j) => {
        //   return `x: ${+(j * ratios[0]).toFixed(2)}<br>y: ${+(i * ratios[0]).toFixed(2)}<br>value: ${item}`})),
        hoverinfo: 'none',
        colorscale: this.colormap$.value.name,
        reversescale: this.reverscale$.value,
        name: `Slice ${index + 1}`,
        visible: index === 0
      };
      traces.push(trace);
    });
    // plot
    return Plotly.newPlot(plotDiv, traces as any,
                  this.getHeatmapLayout([trueImgSize[0], trueImgSize[1]], [trueImgSize[2],
                  trueImgSize[3]]), CONFIG as any).then(() => {
      // handle the relayout event for zoom / rois and the click event
      this.setEvents(plotDiv, true, screenHeight);
      return true;
    });
  }

  /**
   * Function used to plot an RGB heatmap
   * @param plotDiv
   * @param urls
   * @param images
   * @param trueImgSize
   * @param width
   * @param height
   * @param ratios
   * @param screenHeight
   * @return Promise
   */
  private plotRGBHeatmap(plotDiv: string, urls: string[], images: any[], trueImgSize: number[],
                         ratios: number[], width: number, height: number,
                         screenHeight: number): Promise<boolean> {
    // clean up memory
    Plotly.purge(plotDiv);
    this.plotDiv = plotDiv;
    this.imageLength = images.length;
    this.urls = urls;
    this.screenHeight = screenHeight;

    const layout = this.getHeatmapLayout([trueImgSize[0], trueImgSize[1]],
      [trueImgSize[2], trueImgSize[3]]);
    const traces: { z: any; type: string; name: string; visible: boolean; }[] = [];
    images.forEach((dataset, index) => {
      const trace = {
        x0: trueImgSize[0],
        dx: ratios[0],
        y0: trueImgSize[2],
        dy: ratios[0],
        x: Array.from(Array(width).keys()),
        y: Array.from(Array(height).keys()),
        z: dataset,
        // text: dataset.map((row: any[], i: any) => row.map((item, j) => {
        //   return `x: ${+(j * ratios[0]).toFixed(2)}<br>y: ${+(i * ratios[0]).toFixed(2)}<br>value: ${item}`})),
        hoverinfo: 'none',
        type: 'image',
        name: `Slice ${index + 1}`,
        visible: index === 0
      };
      traces.push(trace);
    });
    return Plotly.newPlot(plotDiv, traces as any, layout, CONFIG as any).then(() => {
      // handle the relayout event
      this.setEvents(plotDiv, false, screenHeight);
      return true;
    });
  }

  /**
   * autoscale the plot
   */
  public autoscale() {
    if (this.plotDiv) {
      this.zoomCoordinates = [];
      Plotly.relayout(this.plotDiv, {
        'xaxis.autorange': true,
        'yaxis.autorange': true }
      );
    }
  }

  /**
   * relayout the plot
   */
  public relayout(trueImageSize?: number[]) {
    let imgSize;
    if (trueImageSize) {
      imgSize = trueImageSize;
    } else {
      imgSize = this.trueImgSize;
    }
    if (this.plotDiv) {
      try {
        if (this.zoomCoordinates.length > 0) {
          Plotly.relayout(this.plotDiv, this.getHeatmapLayout(
            [this.zoomCoordinates[0], this.zoomCoordinates[1]],
            [this.zoomCoordinates[3], this.zoomCoordinates[2]]));
        } else {
          Plotly.relayout(this.plotDiv, this.getHeatmapLayout(
            [imgSize[0], imgSize[1]], [imgSize[2], imgSize[3]]));
        }
      } catch (err) {
        console.log('Error occured:' + err);
        this.messageService.add({ sticky: true, severity:'error', summary:'An error occured', detail:`The following
                                  error occured: ${err}. Please try to open the image again through the
                                  file navigator.` });
        // TODO correctly clear the plot
        this.reset();
      }
    }
  }

  private setEvents(plotDiv: string, isGrayscale: boolean, screenHeight: number) {
    this.imageInfo.isGrayscale = isGrayscale;
    this.screenHeight = screenHeight;
    const plot: any = document.getElementById(plotDiv);
    if (plot) {
      // unbind previous event
      plot.removeEventListener('plotly_relayout', this.onRelayoutEvent);
      // bind new event
      plot.on('plotly_relayout', this.onRelayoutEvent);
    }
  }

  private relayoutEventHandler(event: any, fileService: FilesService, plotService: PlotlyService,
                               mainState: MainState) {
    if (Object.keys(event).includes('dragmode')) {
      this.dragMode = event.dragmode;
    }
    // when relayout event occurs
    // update the region selection udpdates
    const keys = Object.keys(event);
    keys.forEach((key: any) => {
      if (typeof key === 'string') {
        if (key.startsWith('shapes[') && this.shapes) {
          const shapeNumber = +key.split('[')[1].split(']')[0];
          const shapeChange = key.split('.')[1];
          if (shapeChange === 'path') {
            this.shapes[shapeNumber][shapeChange] = this.plotUtilities.roundPathCoordinates(event[key]);
          } else {
            this.shapes[shapeNumber][shapeChange] = Math.round(+event[key]);
          }
        }
      }
    });
    // manage high def zoom (not if we are showing a stack)
    if (keys.length === 4 && this.isRealZoom && !this.imageInfo.showStack) {
      const coordinates: any[] = [];
      keys.forEach(key => {
        if (key.startsWith('xaxis.range[')) {
          coordinates.push(event[key]);
        }
        if (key.startsWith('yaxis.range[')) {
          coordinates.push(event[key]);
        }
      });
      if (coordinates.length > 0 && this.trueImgSize) {
        this.zoomCoordinates = coordinates;
        const rect = this.plotUtilities.getRectangle(coordinates, this.trueImgSize);
        // check if zoom is outside of the image boundary
        if (this.plotUtilities.isZoomSameAsImgSize(rect, this.trueImgSize)) {
          this.autoscale();
        } else {
          // set zoom message
          let zoomMsg: string;
          if (!this.imageCached) {
            zoomMsg = 'Creating a first time cached copy of the image.\n' +
              'This is done once for each image so that the next zoom events\n' +
              'will be much faster than this first zoom...'
          } else {
            zoomMsg = 'Please wait while zoomed image is loading...';
          }
          mainState.setImageLoadingMessage(zoomMsg);
          const zoomRequest = new ZoomRequest();
          zoomRequest.roi = rect;
          zoomRequest.screen = this.plotUtilities.getDomRectangle('diagram');
          const fileInfo = fileService.getSelectedFileInfo();
          if (fileInfo) {
            zoomRequest.info = fileInfo.rawData;
            // set new trueImageSize
            const imageSize: any[] = [];
            imageSize[0] = zoomRequest.roi.x;
            imageSize[1] = zoomRequest.roi.x + zoomRequest.roi.width;
            imageSize[2] = zoomRequest.roi.y;
            imageSize[3] = zoomRequest.roi.y + zoomRequest.roi.height;
            // set zIndex (used for image stack and selecting the wanted image in the stack)
            zoomRequest.zIndex = plotService.zIndex.value;
            mainState.setImageLoading(true);
            mainState.setZoom(true);
            fileService.zoomOnRegion(zoomRequest).subscribe({ next: zoomData => {
              // Create a new Uint8Array from the ArrayBuffer
              const uint8Array = new Uint8Array(zoomData);
              // Convert the Uint8Array to a Buffer
              const buffer = Buffer.from(uint8Array);
              Image.load(buffer).then((image: any) => {
                const xRatio = zoomRequest.roi.width / image.width;
                const yRatio = zoomRequest.roi.height / image.height;
                // plot if filename of last selected file is same as loaded filename]
                if (this.fileName === zoomRequest.info.name) {
                  if (this.imageInfo.isGrayscale) {
                    let imgData = this.plotUtilities.arrayToMatrix(image.grey().data, image.width);
                    this.plotHeatmap(this.plotDiv, this.urls,[imgData], imageSize, [xRatio, yRatio],
                      this.screenHeight).then(() => {
                      // set cache to true as next zoom will use the newly created cache
                      mainState.setImageCached(true);
                      mainState.setImageLoading(false);
                      // clean unused var
                      image = null; imgData = [];
                      this.relayout(imageSize);
                    });
                  } else {
                    let rgbMatrix = this.plotUtilities.arrayToMatrix(image.getPixelsArray(), image.width);
                    this.plotRGBHeatmap(this.plotDiv, this.urls, [rgbMatrix], imageSize,
                      [xRatio, yRatio], image.width, image.height, this.screenHeight).then(() => {
                      // set cache to true as next zoom will use the newly created cache
                      mainState.setImageCached(true);
                      // set the yaxis.scale anchor to false to get the zoom roi follow the mouse cursor
                      // with no aspect ratio
                      mainState.setImageLoading(false);
                      // clean unused var
                      image = null; rgbMatrix = [];
                      this.relayout(imageSize);
                    });
                  }
                }
              });
            }, error: err => {
              console.log('Error occured when zooming:' + err);
              this.messageService.add({ sticky: true, severity:'error', summary:'An error occured',
                detail:`The following error occured while zooming: ${err}.
                                  Please try to open the image again through the file navigator and
                                  zoom on the selected area once more.` });
              mainState.setLoadingError(true);
              mainState.setImageLoading(false);
            },
              complete: () => {
                console.log('zooming complete');
              }
            });
          }
        }
      }
    }
    // when shape is created
    if ('shapes' in event) {
      this.shapes = event.shapes;
      for (let i = 0; i < this.shapes.length; i++) {
        if (this.shapes[i].name === undefined) {
          this.shapes[i].name = `shape${i}`;
          if (this.showShapeLabel) {
            this.shapes[i].label = { text: `shape${i}`,
              texttemplate: `shape${i}`,
              font: { color: this.labelColor },
              textposition: 'top left'
            };
          }
        }
        this.shapes[i].fileName = this.fileName;
        this.shapes[i] = this.plotUtilities.snapRegion(this.shapes[i]);
        // update new shapes in region update event
        this.regionUpdateEvent.next(this.shapes);
      }
      // deep copy
      if (this.isRegionSavedOn) {
        this.previousShapes = this.shapes.slice();
      }
    }
    // if autoscale
    if (keys.includes('xaxis.autorange') && keys.includes('yaxis.autorange')) {
      // trigger an autoscale event to reset the selected image mode dropdown
      this.autoscaleEvent.next('an autoscale has happened');
      // select display type to trigger a new plotting update with all the necessary plotting parameters set
      fileService.selectDisplayType(DisplayType.Diagram);
    }
    // if showstack or aspectratio event
    if (event.showstack !== undefined || event.aspectratio !== undefined) {
      // Set new image info to trigger a plot update
      this.setImageInfo(mainState, event.showstack, event.aspectratio);
    }
  }

  public reloadAndPlot() {
    this.setImageInfo(this.mainState);
  }

  private setImageInfo(mainState: MainState, showStack?: boolean, scaleratio?: boolean) {
    // Set new img info with corresponding
    const imgInfo = new ImageInfo();
    if (showStack !== undefined) {
      imgInfo.showStack = showStack;
    }
    if (this.fileName) {
      imgInfo.fileName = this.fileName;
    }
    imgInfo.isGrayscale = this.imageInfo.isGrayscale;
    imgInfo.trueImageSize = [this.trueImgSize[1], this.trueImgSize[3]];
    imgInfo.urls = this.urls;
    imgInfo.isStack = this.urls.length > 1;
    if (scaleratio !== undefined) {
      imgInfo.scaleRatio = scaleratio;
    } else {
      imgInfo.scaleRatio = this.scaleratio;
    }
    mainState.setImageInfo$(imgInfo);
  }

  public getShapes() {
    return this.shapes;
  }

  /**
   * Get all the selected regions as polygons
   */
  public getRegionPolygons(): any[] {
    const ret: any[] = [];
    const figures: any[] = this.shapes;
    figures.forEach((fig: any) => {
      const poly: any = this.plotUtilities.getPolygon(fig);
      if (poly == null) {
        return;
      }
      ret.push(poly);
    });
    return ret;
  }

  /**
   * Sets plot regions. if isRegionSaveOn is true, then the shapes are persisted to the plot
   * @param regions
   * @param showRegionLabel
   * @param isRegionSaveOn
   * @param shapeColor
   * @param fillColor
   * @param labelColor
   */
  public setRegions(regions: Region[], showRegionLabel: boolean, isRegionSaveOn: boolean,
                    shapeColor?: string, fillColor?: string, labelColor?: string) {
    if (shapeColor === undefined) {
      shapeColor = this.shapeColor;
    }
    if (fillColor === undefined) {
      fillColor = this.fillColor;
    }
    if (labelColor === undefined) {
      labelColor = this.labelColor;
    }
    const selections: any[] = [];
    regions.forEach((region: Region) => {
      const bnds = region.bounds;
      if (bnds != undefined) {
        if (bnds instanceof Rectangle || ('width' in bnds && 'height' in bnds)) {
          const rectangle = {
            editable: true,
            type: 'rect',
            x0: bnds.x,
            y0: bnds.y,
            x1: bnds.x + bnds.width,
            y1: bnds.y + bnds.height,
            line: {
              color: shapeColor,
              width: 3
            },
            fileName: this.fileName,
            label: showRegionLabel ? { text: `${region.name}`,
                                       texttemplate: `${region.name}`,
                                       font: { color: labelColor },
                                       textposition: 'top left'
            } : {},
          };
          selections.push(rectangle);
        }
        if (bnds instanceof Polygon || ('xpoints' in bnds && 'ypoints' in bnds)) {
          let path = 'M';
          for (let i = 0; i < bnds.npoints; i++) {
            if (i < bnds.npoints - 1) {
              path = `${path}${bnds.xpoints[i]},${bnds.ypoints[i]}L`;
            } else {
              // for last point
              path = `${path}${bnds.xpoints[i]},${bnds.ypoints[i]}Z`;
            }
          }
          const polygon = {
            editable: true,
            type: 'path',
            path: path,
            line: {
              color: shapeColor,
              width: 3
            },
            fileName: this.fileName,
            label: showRegionLabel ? { text: `${region.name}`,
                                       texttemplate: `${region.name}`,
                                       font: { color: labelColor } } : {},
                                       textposition: 'top left'
          };
          selections.push(polygon);
        }
      }
    });
    this.isRegionSavedOn = isRegionSaveOn;
    if (isRegionSaveOn) {
      // we save the new regions
      this.showShapeLabel = showRegionLabel;
      this.shapeColor = shapeColor;
      this.labelColor = labelColor;
      this.fillColor = fillColor;
      this.shapes = selections.slice();
    }
    Plotly.relayout(this.plotDiv, { shapes: selections, activeshape: { fillcolor: this.fillColor } } as any);

    this.regionUpdateEvent.next(selections);
  }

  getShowShapeLabel() {
    return this.showShapeLabel;
  }

  /**
   * Set previous saved shapes
   */
  public setPreviousShapes() {
    Plotly.relayout(this.plotDiv, { shapes: this.previousShapes });
  }

  private getHeatmapLayout(xRange: number[], yRange: number[]): any {
    return {
      xaxis: {
        constrain: 'range',
        constraintoward: 'center',
        side: 'top',
        ticks: '',
        range: xRange
      },
      yaxis: {
        constrain: 'range',
        constraintoward: 'center',
        range: yRange,
        ticks: '',
        ticksuffix: '  ',
        autorange: 'reversed',
        scaleanchor: this.scaleratio ? 'x' : false,
        scaleratio: 1,
      },
      height: this.screenHeight,
      sliders: [{
        pad: { t: 50 },
        currentvalue: {
          visible: true,
          prefix: 'Z-plane:',
          xanchor: 'right',
        },
        steps: this.getSteps()
      }],
      autosize: true,
      shapes: this.shapesToRedraw(this.showShapeLabel),
      activeshape: { fillcolor: this.fillColor },
      dragmode: this.dragMode ? this.dragMode : false,
      newshape: { line: { color: this.shapeColor, width: 3 } }
    };
  }

  private shapesToRedraw(showLabel: boolean) {
    const shapesToRedraw: ShapeSelection[] = [];
    for (const shape of this.shapes) {
      if (JSON.stringify(shape.fileName) === JSON.stringify(this.fileName)) {
        shape.label = showLabel ? { text: `${shape.name}`, texttemplate: `${shape.name}`, textposition: 'top left' } : {};
        shapesToRedraw.push(shape);
      }
    }
    return shapesToRedraw;
  }
  /**
   * Unused (will be used when surface plot is added)
   * @param xRange
   * @param yRange
   * @private
   */
  private getSurfaceLayout(xRange: number[], yRange: number[]): any {
    return {
      margin: { t: 0, b: 0, l: 0, r: 0 },
      scene: {
        xaxis: {
          gridcolor: 'rgb(255, 255, 255)',
          zerolinecolor: 'rgb(255, 255, 255)',
          showbackground: true,
          backgroundcolor: 'rgb(230, 230,230)',
          range: xRange
        },
        yaxis: {
          gridcolor: 'rgb(255, 255, 255)',
          zerolinecolor: 'rgb(255, 255, 255)',
          showbackground: true,
          backgroundcolor: 'rgb(230, 230, 230)',
          autorange: 'reversed',
          range: yRange,
          scaleanchor: 'x'
        },
        zaxis: {
          gridcolor: 'rgb(255, 255, 255)',
          zerolinecolor: 'rgb(255, 255, 255)',
          showbackground: true,
          backgroundcolor: 'rgb(230, 230,230)'
        },
        aspectratio: { x: 1, y: 1, z: 0.7 },
        aspectmode: 'manual'
      }
    };
  }

  private getSteps() {
    const steps = [];
    for (let i = 0; i < this.imageLength; i++) {
      steps.push({
        label: i + 1,
        method: 'restyle',
        args: ['visible', Array(this.imageLength).fill(false).fill(true, i, i + 1)],
      });
    }
    return steps;
  }

  public reset() {
    if (this.plotDiv) {
      Plotly.newPlot(this.plotDiv, [],
        this.getHeatmapLayout([0, 100], [100, 0]), CONFIG as any);
    }
  }

  public isStackLoading$(): Observable<boolean> {
    return this.stackLoading$.asObservable();
  }
  public setStackLoading(stackLoading: boolean) {
    this.stackLoading$.next(stackLoading);
  }
  public getStackLoadingProgress$(): Observable<number> {
    return this.stackLoadingProgress$.asObservable();
  }

  getColormapOptions() {
    return COLORMAP_OPTIONS;
  }
  setColormap(colormap: any) {
    this.colormap$.next(colormap);
    Plotly.restyle(this.plotDiv, { 'colorscale': colormap.name });
  }
  getColormap() {
    return this.colormap$.asObservable();
  }
  setReverscale(reverscale: any) {
    this.reverscale$.next(reverscale);
    Plotly.restyle(this.plotDiv, { 'reversescale': reverscale });
  }

  setScaleRatio(scaleratio: boolean) {
    this.scaleratio$.next(scaleratio);
    this.scaleratio = scaleratio;
    this.relayout(this.trueImgSize);
  }

  getReverseScale() {
    return this.reverscale$.asObservable();
  }

  getScaleRatio() {
    return this.scaleratio$.asObservable();
  }

  setShowStack(showstack: boolean) {
    if (!showstack) {
      this.zIndex.next(0);
    }
    this.imageInfo.showStack = showstack;
    this.stackLoading$.next(showstack);
    Plotly.relayout(this.plotDiv, { 'showstack': showstack } as any);
  }

  getAutoscaleEvent() {
    return this.autoscaleEvent.asObservable();
  }

  getRegionUpdateEvent() {
    return this.regionUpdateEvent.asObservable();
  }
  setZIndex(zIndex: number) {
    this.zIndex.next(zIndex);
  }

  getShapeColor() {
    return this.shapeColor;
  }
  getLabelColor() {
    return this.labelColor;
  }
  getFillColor() {
    return this.fillColor;
  }

  /**
   * Unsubscribe Subscriptions
   */
  unsubscribe() {
    if (this.imageCachedSubscription) {
      this.imageCachedSubscription.unsubscribe();
    }
    if (this.filenameSubscription) {
      this.filenameSubscription.unsubscribe();
    }
  }
}
