<home *ngIf="(auth.isAuthenticated$ | async) === false; else mainContent"> </home>

<ng-template #mainContent>
  <div id="main-grid-panel">
    <as-split direction="horizontal" [useTransition]="true" (dragEnd)="splitDragEvent($event)" >
      <as-split-area [size]=20>
        <!-- <div class="col flex justify-content-center"> -->
        <p-tabMenu
          #filesDetailsTabMenu
          [model]="itemsTabMenu | slice : 0 : 2"
          [activeItem]="(activeItemL$ | async)!"
        ></p-tabMenu>

        <div *ngIf="activeItemL$ | async as activeItemL">
          <ng-container *ngIf="activeItemL.id === 'files'">
            <div class="w-full col-12 md:col-3">
              <p-dropdown
                placeholder="Select Bucket"
                [options]="projects"
                optionLabel="name"
                [disabled]="projects.length <= 0"
                (onChange)="onProjectSelect($event)"
                [dropdownIcon]="loadingDropdown()"
              ></p-dropdown>
            </div>

            <file-tree
              *ngIf="this.mainFacade.getSelectedProject$() | async"
              (interuptPreview)="interuptPreview()"
              (processSelected)="processSelected()"
              (nodeExpand)="nodeExpand($event)"
              (nodeSelect)="nodeSelect($event)"
            ></file-tree>
          </ng-container>

          <ng-container *ngIf="activeItemL.id === 'details'">
            <cimg-details></cimg-details>
          </ng-container>
        </div>
        <!-- </div> -->
      </as-split-area>
      <as-split-area [size]=60>
        <file-viewer></file-viewer>
      </as-split-area>
      <as-split-area [size]=20>
        <p-tabMenu
          #processQueueTabMenu
          [model]="itemsTabMenu | slice : 2 : 4"
          [activeItem]="(activeItemR$ | async)!"
        ></p-tabMenu>

        <div *ngIf="activeItemR$ | async as activeItemR">
          <ng-container *ngIf="activeItemR.id === 'process'">
            <process></process>
          </ng-container>

          <process-status *ngIf="activeItemR.id === 'queue'"> </process-status>
        </div>
      </as-split-area>
    </as-split>
  </div>
</ng-template>

<p-toast position="center"></p-toast>

