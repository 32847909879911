import { Injectable } from '@angular/core';
import { User } from '@auth0/auth0-spa-js';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {

  private user$ = new BehaviorSubject<User | null>(null);

  getUser$(): Observable<User | null> {
    return this.user$.asObservable();
  }

  setUser(user: User) {
    this.user$.next(user);
  }

}
