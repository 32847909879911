import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Observer, of, timer, Subscription } from 'rxjs';
import { switchMap, share, retry, takeWhile } from 'rxjs/operators';

import { Instruction, Request, ProcessType, Validation } from '../main/models/request';

import { Status, State, StringResponse, isRunning } from '../main/models/status';
import { environment } from '../../environments/environment';
import { IFileInfo } from '../main/models/file-info';
import { ProcessStatus } from '../main/models/process-status';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  private static processNumber = 0;
  private INTERVAL = 5000; // in milliseconds
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.slideCropServer;
  }

  public run(type: ProcessType, instr: Instruction) : Observable<StringResponse> {
    if (instr == null) throw new Error('A valid crops instruction must be provided!');
    if (instr.file == null) throw new Error('The file must not be null!');

    const url: string = this.createUrl(type, instr);
    return this.http.get<StringResponse>(url);
  }

  public validate(type: ProcessType, instr: Instruction) : Observable<Validation> {
    if (instr == null) throw new Error('A valid instruction is required for process validation');
    if (instr.file == null) throw new Error('A file is required for process validation');

    const json: string = JSON.stringify(instr);

    const base64: string = btoa(json);
    const queryParams = { type: type, instr: base64 };
    const url = new URL(`${this.url}validate`);
    url.search = new URLSearchParams(queryParams).toString();
    return this.http.get<Validation>(url.toString());
  }

  public createMonitorUrl(workflowId: string): URL {
    const queryParams = { workflowId: workflowId };
    const url = new URL(`${this.url}status`);
    url.search = new URLSearchParams(queryParams).toString();
    return url;
  }

  /**
   Monitor a given url for each time it returns status.
   Look for a status which is final such as COMPLETE or ERROR
   and then stop monitoring.
   */
  public monitor(url: URL, observer: Observer<Status>) : Subscription {
    // From this https://blog.angulartraining.com/how-to-do-polling-with-rxjs-and-angular-50d635574965
    // We try to poll every internal and stop when state is not running.
    const events : Observable<Status> = timer(0, this.INTERVAL).pipe(
      switchMap(()=>{
        const ob : Observable<Status> = this.http.get<Status>(url.toString());
        return ob;
      }),
      retry(),
      share(),
      takeWhile((status:Status)=>{
        // A non status might just mean not started yet.
        if (!status) {
          return true;
        } else {
          const state: State | undefined = (status) ? status.state : undefined;
          if (state) {
            const busy: boolean = isRunning(state);
            return busy;
          } else {
            return true;
          }
        }
      }, true));

    return events.subscribe(observer);
  }

  private createUrl(type: ProcessType, instr: Instruction): string {
    // Set a counter so that processing the same file twice
    // does not generate the same get endpoint.
    ProcessService.processNumber++;
    instr.file.processNumber = ProcessService.processNumber;
    const json: string = JSON.stringify(instr);
    const base64: string = btoa(json);
    const furl: string = this.url + 'process?type=' + type + '&instr=' + base64;
    return furl;
  }

  public getDefaultRequest(type: ProcessType, file: IFileInfo) : Observable<Request> {
    let path = this.url + 'process/default?type=' + type;
    if (file != null) {
      const base64: string = this.encode(file);
      path = path + '&info=' + base64;
    }
    return this.http.get<Request>(path);
  }

  public encode(file: IFileInfo): string {
    const json: string = JSON.stringify(file);
    return btoa(json);
  }

  public getStatusByUserName(userName: string) {
    const queryParams = { userName: userName };
    const url = new URL(`${this.url}status-by-user`);
    url.search = new URLSearchParams(queryParams).toString();
    return this.http.get<ProcessStatus[]>(url.toString());
  }

  public terminateProcess(workflowId: string) {
    const queryParams = { workflowId: workflowId };
    const url = new URL(`${this.url}terminate`);
    url.search = new URLSearchParams(queryParams).toString();
    return this.http.get(url.toString());
  }

}
