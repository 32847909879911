<div class="header">
  <p-toolbar styleClass="p-0 bg-white border-noround px-2 toolbar-nav">
    <div class="w-8 p-toolbar-group-left flex">
      <img src="./assets/jax-logo.png" alt="JAX Logo" class="mx-2 mt-1" />
      <h2 class="font-semibold text-indigo-800 uppercase">
        {{ title }}
      </h2>
    </div>

    <div class="align-items-right px-4">
      <ng-container *ngIf="user$ | async as user">
        <i class="pi pi-user"></i>

        <span class="font-medium pl-2">{{ user.name }} <{{ user.email }}></span>
      </ng-container>
    </div>

    <div class="p-toolbar-group-right">

      <div *ngIf="(auth.isAuthenticated$ | async) === false; then loginSection; else userMenu"></div>

      <ng-template #loginSection>
        <button
          pButton
          type="button"
          id="login-button"
          class="p-button-text"
          label="Sign In"
          icon="pi pi-sign-in"
          (click)="login()"
        ></button>
      </ng-template>

      <ng-template #userMenu>
        <button
          pButton
          type="button"
          id="logout-button"
          class="p-button-text p-button-plain"
          label="Sign Out"
          icon="pi pi-sign-out"
          (click)="logout()"
        ></button>
      </ng-template>
    </div>
  </p-toolbar>
</div>

