import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IFileInfo } from '../../models/file-info';
import { State, Status } from '../../models/status';

@Injectable({
  providedIn: 'root',
})
export class ProcessState {

  private request$: BehaviorSubject<Request | null> = new BehaviorSubject<Request | null>(null);
  private siftFiles$: BehaviorSubject<IFileInfo[]> = new BehaviorSubject<IFileInfo[]>([]);
  private isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private status$!: BehaviorSubject<Status>;

  constructor() {
    this.status$ = new BehaviorSubject<Status>(this.createReadyStatus());
  }

  getRequest$() {
    return this.request$.asObservable();
  }

  setRequest$(request: Request | null) {
    this.request$.next(request);
  }

  getSiftFiles$() {
    return this.siftFiles$.asObservable();
  }

  setSiftFiles$(files: IFileInfo[]) {
    this.siftFiles$.next(files);
  }

  getIsProcessing$() {
    return this.isProcessing$.asObservable();
  }

  setIsProcessing$(isProcessing: boolean) {
    this.isProcessing$.next(isProcessing);
  }

  getStatus$() {
    return this.status$.asObservable();
  }

  setStatus(status: Status) {
    this.status$.next(status);
  }

  private createReadyStatus() : Status {
    return {
      completePercent: 40.0,
      message: '  Ready message',
      state: State.NONE
    };
  }

}
