import { Polygon, Rectangle } from './models/plotting/region';
import { ShapeSelection } from './models/plotting/shape';

export const COLORMAP_OPTIONS = [{ name:'Greys', src:'../assets/icons/colormap-greys.png' },
  { name:'Blackbody', src:'../assets/icons/colormap-blackbody.png' },
  { name:'Bluered', src:'../assets/icons/colormap-bluered.png' },
  { name:'Cividis', src:'../assets/icons/colormap-cividis.png' },
  { name:'Earth', src: '../assets/icons/colormap-earth.png' },
  { name:'Electric', src: '../assets/icons/colormap-electric.png' },
  { name:'Greens', src:'../assets/icons/colormap-greens.png' },
  { name:'Hot', src:'../assets/icons/colormap-hot.png' },
  { name:'Jet', src:'../assets/icons/colormap-jet.png' },
  { name:'Picnic', src:'../assets/icons/colormap-picnic.png' },
  { name:'Portland', src:'../assets/icons/colormap-portland.png' },
  { name:'Rainbow', src:'../assets/icons/colormap-rainbow.png' },
  { name:'RdBu', src:'../assets/icons/colormap-rdbu.png' },
  { name:'Reds', src:'../assets/icons/colormap-reds.png' },
  { name:'Viridis', src:'../assets/icons/colormap-viridis.png' },
  { name:'YlGnBu', src:'../assets/icons/colormap-ylgnbu.png' },
  { name:'YlOrRd', src:'../assets/icons/colormap-ylorrd.png' }];

export const CONFIG = {
  displaylogo: false, // Hide the plotly logo
  responsive: true, // Make the plot responsive
  displayModeBar: true,
  scrollZoom: false, // disable mouse scroll zoom
  modeBarButtons: [['toImage', 'autoScale2d', 'resetScale2d',
    'zoom2d', 'pan2d', 'zoomIn2d', 'zoomOut2d', 'drawclosedpath', 'drawrect', 'eraseshape'
  ]]
};
export class PlotUtilities {

  /**
   * Rounds all the point coordinates of a path ('M13.54,54.566L35.44,33.3L36.22,89.6Z')
   * becomes 'M14,55L35,33L36,90Z'
   * @param path
   */
  public roundPathCoordinates(path: string) {
    let roundedPath = 'M';
    const strCoord = path.substring(1, path.length - 1);
    const strArray = strCoord.split('L');
    for (let i = 0; i < strArray.length; i++) {
      const xy = strArray[i].split(',');
      if (i < strArray.length - 1) {
        roundedPath = `${roundedPath}${Math.round(+xy[0])},${Math.round(+xy[1])}L`;
      } else {
        // last iteration
        roundedPath = `${roundedPath}${Math.round(+xy[0])},${Math.round(+xy[1])}Z`;
      }
    }
    return roundedPath;
  }

  /**
   * Transform a 1d array into a matrix given a given width
   * @param array Uint8Array
   * @param elementsPerSubArray
   */
  public arrayToMatrix(array: any[] | Uint8Array, elementsPerSubArray: number) {
    const matrix: any[] = [];
    let i, k;
    for (i = 0, k = -1; i < array.length; i++) {
      if (i % elementsPerSubArray === 0) {
        k++;
        matrix[k] = [];
      }
      matrix[k].push(array[i]);
    }
    return matrix;
  }

  /**
   * coordinates are of the bottom left and upper right corners of the rectangle, The coordinates
   * are taken given a yAxis that is up side down (as for all images). If Zoom is out of the image boundary,
   * it will return the image size coordinates for the new rectangle.
   * @param coordinates plotly coordinates ([Xaxis.range[0], xAxis.range[1], yAxis.range[0], yaxis.range[1]])
   * @param trueImageSize true image size [0, x, 0, y]
   */
  public getRectangle(coordinates: number[], trueImageSize: number[]) {
    const rect = new Rectangle();
    // check if new image size is bigger than original image size
    if (coordinates[3] < 0) {
      coordinates[3] = 0;
    }
    if (coordinates[1] > trueImageSize[1]) {
      coordinates[1] = trueImageSize[1];
    }
    if (coordinates[2] > trueImageSize[3]) {
      coordinates[2] = trueImageSize[3];
    }
    if (coordinates[0] < 0) {
      coordinates[0] = 0;
    }
    // if coordinates outside of image left/right/top/bottom
    // we set the coordinates to the original image size.
    if (coordinates[1] < 0 || coordinates[0] > trueImageSize[1]
      || coordinates[2] < 0 || coordinates[3] > trueImageSize[3]) {
      coordinates[0] = 0;
      coordinates[1] = trueImageSize[1];
      coordinates[2] = trueImageSize[3];
      coordinates[3] = 0;
    }
    rect.x = Math.floor(coordinates[0]);
    rect.y = Math.floor(coordinates[3]);
    rect.width = Math.floor(coordinates[1] - coordinates[0]);
    rect.height = Math.floor(coordinates[2] - coordinates[3]);
    return rect;
  }

  /**
   * given a dom object (found by id), return a Rectangle of the bounding element.
   * @param div
   */
  public getDomRectangle(div: string) {
    const domRect = new Rectangle();
    const appDiv: HTMLElement | null = document.getElementById(div);
    if (appDiv && appDiv.parentNode && appDiv.parentNode.parentElement) {
      const rect = appDiv.parentNode.parentElement.getBoundingClientRect();
      domRect.x = Math.round(rect.x);
      domRect.y = Math.round(rect.y);
      domRect.width = Math.round(rect.width);
      domRect.height = Math.round(rect.height);
    }
    return domRect;
  }

  /**
   * snap region  to closest pixel in the coordinate (round the coordinates of the region)
   * @param shape
   * @private
   */
  public snapRegion(shape: ShapeSelection) {
    // if region is a polygon
    if (shape.path) {
      shape.path = this.roundPathCoordinates(shape.path);
    } else if (shape.x0 && shape.x1 && shape.y0 && shape.y1) {
      // if region is a rectangle
      if (typeof shape.x0 === 'number') {
        shape.x0 = Math.round(shape.x0);
      }
      if (typeof shape.x1 === 'number') {
        shape.x1 = Math.round(shape.x1);
      }
      if (typeof shape.y0 === 'number') {
        shape.y0 = Math.round(shape.y0);
      }
      if (typeof shape.y1 === 'number') {
        shape.y1 = Math.round(shape.y1);
      }
    }
    return shape;
  }

  /**
   * Given a figure (path or rectangle), returns a polygon
   * @param fig
   */
  public getPolygon(fig: any): any {
    const poly: any = new Polygon();
    if (fig.type === 'path') {
      const tempPath = fig.path as string;
      const path = tempPath.substring(1, tempPath.length - 1);
      const verts: string[] = path.split('L');
      poly.npoints = verts.length;
      poly.xpoints = [];
      poly.ypoints = [];
      verts.forEach((pnt: any) => {
        const point = pnt.split(',');
        poly.xpoints.push(Math.round(point[0]));
        poly.ypoints.push(Math.round(point[1]));
      });
    } else if (fig.type === 'rect') {
      poly.npoints = 4;
      poly.xpoints = this.round([fig.x0, fig.x1, fig.x1, fig.x0]);
      poly.ypoints = this.round([fig.y1, fig.y1, fig.y0, fig.y0]);
    } else {
      console.log('Ignoring unrecognised shape in diagram');
      return null;
    }
    return poly;
  }

  /**
   * TESTED
   * Rounds a list of numbers
   * @param a
   */
  public round(a: number[]): number[] {
    const b: number[] = [];
    a.forEach((d: number) => b.push(Math.round(d)));
    return b;
  }

  /**
   * TESTED
   * Returns true of zoom is the same as image size
   * @param rect Rectangle area
   * @param trueImgSize
   * @return true if rect is out of image size boundary
   */
  public isZoomSameAsImgSize(rect: Rectangle, trueImgSize: number[]) {
    return rect.x === trueImgSize[0] && rect.y === trueImgSize[2]
      && rect.width === trueImgSize[1] && rect.height === trueImgSize[3];
  }
}
