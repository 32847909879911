import { Component, OnInit } from '@angular/core';

import { UserFacade } from './main/UserFacade';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'cimg-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private userFacade: UserFacade,
              public auth: AuthService) {}

  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      if (user) {
        this.userFacade.setUser(user);
      }
    });
  }

}
