import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Status } from '../../../models/status';

@Component({
  selector: 'process-control',
  templateUrl: './process-control.component.html',
  styleUrls: ['./process-control.component.scss'],
})
export class ProcessControlComponent {

  @Input() runDisabled!: boolean | null;
  @Input() isProcessing!: boolean | null;
  @Input() status!: Status | null;

  @Output() queueProcess = new EventEmitter<null>();
  @Output() runProcess = new EventEmitter<null>();

  submit(event: any) {
    this.queueProcess.emit();
  }

  run(event: any) {
    this.runProcess.emit();
  }

}
