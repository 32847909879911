<h4 class="font-light text-base mb-3 mt-1">
  Select 8bit, 16bit, RGB or multichannel z-stack .TIFF
</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <p-divider align="left">
    <div class="inline-flex align-items-center">
      <b>Settings</b>
    </div>
  </p-divider>

  <!-- gradient kernel -->
  <div class="field grid h-3rem align-items-center my-1">
    <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Gradient Kernel
        <i class="pi pi-info-circle px-1"
          pTooltip="Size of kernel used to measure focus - use larger values for noisier input"
          tooltipPosition="top"
        ></i>
    </label>
    <!--
    <div class="col-12 md:col-7">
      <p-inputNumber
        inputId="gradientKernel"
        formControlName="gradientKernel"
        [showButtons]="true"
        [min]="0"
        [max]="10"
      ></p-inputNumber>
    </div> -->
    <div class="col-12 md:col-7">
      <p-dropdown
        formControlName="gradientKernel"
        placeholder="SELECT SIZE"
        [options]="gradientKernelOptions"
        optionLabel="name"
        class="p-inputtext-sm"
        optionValue="value"
      ></p-dropdown>
    </div>
  </div>

  <!-- noise filter -->
  <div class="field grid h-3rem align-items-center my-1">
    <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Noise Filter
        <i class="pi pi-info-circle px-1"
          pTooltip="Size of noise filter applied to z-map"
          tooltipPosition="top"
        ></i>
    </label>
    <div class="col-12 md:col-7">
      <!--
      <p-inputNumber
        inputId="noiseFilter"
        formControlName="noiseFilter"
        [showButtons]="true"
        [min]="0"
        [max]="10"
      ></p-inputNumber> -->
      <p-dropdown
        formControlName="noiseFilter"
        placeholder="SELECT SIZE"
        [options]="noiseFilterSizesOptions"
        optionLabel="name"
        class="p-inputtext-sm"
        optionValue="value"
      ></p-dropdown>
    </div>
  </div>

  <!-- low pass -->
  <div class="field grid h-3rem align-items-center my-1">
    <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Low-Pass Filter
        <i class="pi pi-info-circle px-1"
          pTooltip="Size of z-map smoothing filter"
          tooltipPosition="top"
        ></i>
    </label>
    <div class="col-12 md:col-7">
      <p-inputNumber
        inputId="lowPass"
        formControlName="lowPass"
        [showButtons]="true"
        [min]="0"
        [max]="25"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>

  <!-- image output -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <div class="field grid h-3rem align-items-center">
      <label for="fnOutputFolder" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Output Folder:
      </label>
      <div class="col-12 md:col-7">
      <span *ngIf="!isOutputFolderEditable">
        {{form.controls['outputFolder'].value}}
        <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputFolderName(true)"></i>
      </span>
        <span *ngIf="isOutputFolderEditable" class="p-input-icon-right">
        <i class="pi pi-times icon-hover" (click)="onEditOutputFolderName(false)"></i>
        <input id="fnOutputFolder" type="text" class="p-inputtext-sm w-full"
               formControlName="outputFolder" pInputText/>
      </span>
      </div>
    </div>

    <div class="field grid h-3rem align-items-center">
      <label for="outputImage" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Result Image:
        <i class="pi pi-info-circle px-1"
          pTooltip="Resulting image with the same resolution, pixel depth, and number of channels"
          tooltipPosition="top"
        ></i>
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isOutputImageEditable">
          {{form.controls['outputImage'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputImage(true)"></i>
        </span>
        <span *ngIf="isOutputImageEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditOutputImage(false)"></i>
          <input
            id="outputImage"
            pInputText
            class="p-inputtext-sm w-full"
            formControlName="outputImage"
          />
        </span>
      </div>
    </div>

    <!-- z-map output -->
    <div class="field grid h-3rem align-items-center">
      <label for="outputZmap" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Result Z-map:
        <i class="pi pi-info-circle px-1"
           pTooltip="Resulting Z-map file to be created"
           tooltipPosition="bottom"
        ></i>
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isOutputZMapEditable">
          {{form.controls['outputZmap'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputZMap(true)"></i>
        </span>
        <span *ngIf="isOutputZMapEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditOutputZMap(false)"></i>
          <input
            id="outputZmap"
            pInputText
            class="p-inputtext-sm w-full"
            formControlName="outputZmap"
          />
        </span>
      </div>
    </div>

    <div class="pt-3 pb-3">
      <span *ngIf="validationMessage" style="color: red">{{ validationMessage }}</span>
    </div>

    <p-button
      type="submit" icon="pi pi-play"
      tooltipPosition="top"
      label="Run"
      styleClass="p-button-primary"
    ></p-button>

  </ng-container>
</form>
