import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProcessState } from './process.state';
import { MainFacade } from '../../main.facade';
import { IFileInfo } from '../../models/file-info';
import { Status } from '../../models/status';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ProcessFacade {

  private selectedType: any = null;
  private edofFormGroup: FormGroup | null = null;
  private htQuantFormGroup: FormGroup | null = null;
  private segmentationFormGroup: FormGroup | null = null;
  private siftFormGroup: FormGroup | null = null;

  constructor(private processState: ProcessState) {
  }

  public trackLoadedFiles(mainFacade: MainFacade) {
    mainFacade.getFileInfo$().subscribe({
      next: fileInfo => {
        this.processState.setSiftFiles$(fileInfo);
      },
    });
  }

  getRequest$(): Observable<Request | null> {
    return this.processState.getRequest$();
  }

  getSiftFiles$(): Observable<IFileInfo[]> {
    return this.processState.getSiftFiles$();
  }

  getIsProcessing$(): Observable<boolean> {
    return this.processState.getIsProcessing$();
  }

  getStatus$(): Observable<Status> {
    return this.processState.getStatus$();
  }

  getSelectedType() {
    return this.selectedType;
  }

  setSelectedType(type: any) {
    this.selectedType = type;
  }

  getEdofFormGroup(): FormGroup | null {
    return this.edofFormGroup;
  }

  setEdofFormGroup(formGroup: FormGroup | null) {
    this.edofFormGroup = formGroup;
  }

  getHtQuantFormGroup(): FormGroup | null {
    return this.htQuantFormGroup;
  }

  setHtQuantFormGroup(formGroup: FormGroup | null) {
    this.htQuantFormGroup = formGroup;
  }

  getSegmentationFormGroup(): FormGroup | null {
    return this.segmentationFormGroup;
  }

  setSegmentationFormGroup(formGroup: FormGroup) {
    this.segmentationFormGroup = formGroup;
  }

  getSiftFormGroup(): FormGroup | null {
    return this.siftFormGroup;
  }

  setSiftFormGroup(formGroup: FormGroup) {
    this.siftFormGroup = formGroup;
  }

}
