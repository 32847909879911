<p-table [columns]="tableData.meta" [value]="tableData.data" [loading]="isLoading"
         styleClass="p-datatable-striped"
         [resizableColumns]="true"
         [scrollable]="true"
         scrollHeight="800px"
         (sortFunction)="alphaNumericSort($event)"
         [customSort]="true">

  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns"
          pSortableColumn="{{col.field}}">
        {{col.header}}
        <p-sortIcon field="{{col.field}}"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data let-columns="columns">
    <tr>
      <td *ngFor="let col of columns">
        {{data[col.field]}}
      </td>
    </tr>
  </ng-template>
</p-table>
