import { Component, Input, OnInit } from '@angular/core';

import { MainFacade } from '../../main.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'code-panel',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent implements OnInit {

  content = '';
  isLoading$!: Observable<boolean>;

  constructor(private mainFacade: MainFacade) {
  }

  ngOnInit(): void {
    this.isLoading$ = this.mainFacade.getFileLoading$();
    this.mainFacade.getFileContent$().subscribe({
      next: (data:string | null) => {
        if (data !== null) {
          this.content = data;
        } else {
          this.content = '';
        }
      }
    });
  }
}
