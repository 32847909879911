<h4 class="font-light text-base mb-3 mt-1">
  Select .NDPI image(s) to process.
</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Region of Interest (ROI)
    </div>
  </p-divider>

  <div formGroupName="regionSettings">
    <div class="field-checkbox">
      <p-checkbox
        formControlName="findRegions"
        [binary]="true"
        class="my-1"
        inputId="findRegions"
      ></p-checkbox>
      <label for="findRegions">Automatically find regions to crop</label>
    </div>

    <div class="formgroup-inline">
      <span id="cropAsText" class="mr-2 font-medium">Crop as:</span>
      <div class="field-radiobutton">
        <p-radioButton
          formControlName="cropFoundRegionsAsRectangles"
          [value]=true
          inputId="rectangle"
        ></p-radioButton>
        <label for="rectangle">Rectangle</label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          formControlName="cropFoundRegionsAsRectangles"
          [value]=false
          inputId="polygon"
        ></p-radioButton>
        <label for="polygon">
          Polygon
        </label>
      </div>
    </div>

    <div class="field-checkbox">
      <p-checkbox
        formControlName="forceSquare"
        [binary]="true"
        class="my-1"
        inputId="forceSquare"
      ></p-checkbox>
      <label for="forceSquare">Force regions to be square</label>
    </div>

    <!-- set margin -->
    <div class="field grid align-items-center my-1">
      <label for="margin" class="col-12 md:col-6 mb-0 text-black-alpha-90">
          Set margin (px)
          <i class="pi pi-info-circle px-2"
            pTooltip="Adds borders to the ROI"
            tooltipPosition="top"
          ></i>
      </label>
      <div class="col-12 md:col-6">
        <p-inputNumber
          inputId="margin"
          formControlName="margin"
          [showButtons]="true"
          [min]="0"
          class="p-inputtext-sm"
        ></p-inputNumber>
      </div>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Format
    </div>
  </p-divider>

  <div formGroupName="regionSettings">
    <div class="field-checkbox">
      <p-checkbox
        formControlName="generatePreviews"
        [binary]="true"
        class="my-1"
        inputId="generatePreviews"
      ></p-checkbox>
      <label for="generatePreviews">
        Generate previews for segments
        <i class="pi pi-info-circle px-2"
          pTooltip="Advisable for large features"
          tooltipPosition="top"
        ></i>
      </label>
    </div>

    <div class="field-checkbox">
      <p-checkbox
        formControlName="zip"
        [binary]="true"
        class="my-1"
        inputId="zip"
      ></p-checkbox>

      <label for="zip">
        GZip results images
        <i class="pi pi-info-circle px-2"
          pTooltip="Write *.tiff as *.tiff.gz"
          tooltipPosition="top"
        ></i>
      </label>
    </div>
  </div>


  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Resolution
    </div>
  </p-divider>

  <div class="field grid">
    <label for="crop-resolution" class="col-12 mb-2 md:col-9 md:mb-0">
      Set crop resolution (ndpi stack index)
      <i class="pi pi-info-circle px-2"
         pTooltip="Choose an integer between 0 and 3. 0 for full resolution."
         tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-3">
      <input id="crop-resolution" type="text" formControlName="cropResolution"
        class="text-base text-color surface-overlay p-2 border-1
          border-solid surface-border border-round appearance-none
          outline-none focus:border-primary w-full" pInputText>
    </div>
  </div>

  <div formGroupName="tileConfiguration" class="field-checkbox">
    <p-checkbox
      formControlName="alwaysTile"
      [binary]="true"
      class="my-1"
      inputId="alwaysTile"
    ></p-checkbox>

    <label for="alwaysTile">
      Tile image (px)
      <i class="pi pi-info-circle px-2"
        pTooltip="Splits an .NDPI image into small patches"
        tooltipPosition="top"
      ></i>
    </label>
  </div>

  <div formGroupName="tileConfiguration">
    <div formArrayName="tileSize">
      <div class="field grid">
        <label for="title_weight" class="md:col-3">Tile Width:</label>
        <div class="md:col-3">
          <input id="tile_width" type="text" [formControlName]="0"
            class="text-base text-color surface-overlay p-2 border-1
              border-solid surface-border border-round appearance-none
              outline-none focus:border-primary w-full">
        </div>
        <label for="title_height" class="md:col-2">Height:</label>
        <div class="md:col-3">
          <input id="tile_height" type="text" [formControlName]="1"
            class="text-base text-color surface-overlay p-2 border-1
              border-solid surface-border border-round appearance-none
              outline-none focus:border-primary w-full">
        </div>
      </div>
    </div>
  </div>

  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Color
    </div>
  </p-divider>

  <div formGroupName="regionSettings">
    <div class="formgroup-inline">
      <span id="outputAsText" class="mr-2 font-medium">Output as:</span>
      <div class="field-radiobutton">
        <p-radioButton
          formControlName="cropAsGreyScale"
          [value]=false
          inputId="cropRGB"
        ></p-radioButton>
        <label for="cropRGB">RGB</label>
      </div>
      <div class="field-radiobutton">
        <p-radioButton
          formControlName="cropAsGreyScale"
          [value]=true
          inputId="cropGreyScale"
        ></p-radioButton>
        <label for="cropGreyScale">
          Greyscale
          <i class="pi pi-info-circle px-2"
            pTooltip="Approximately 1/3 file size"
            tooltipPosition="top"
          ></i>
        </label>
      </div>
    </div>
  </div>

  <div class="pt-3 pb-3 process-buttons">
    <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
		<br *ngIf="validationMessage"/>

    <p-button pTooltip="Find automatic regions and plot them over the image."
              label="Find"
              icon="pi pi-search"
              styleClass="p-button-secondary my-2"
              (click)="find()"
    ></p-button>
    &nbsp;
    <p-button type="submit" icon="pi pi-play"
              pTooltip="Run the slide cropping and produce a directory of cropped images from the slide."
              label="Run"
              styleClass="p-button-primary my-2"
    ></p-button>
  </div>

</form>
