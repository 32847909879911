import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent {

  title = 'Image Tools';
  user$ = this.auth.user$;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) { }

  login() {
    this.auth.loginWithRedirect();
  }

  logout() {
    this.auth.logout(
      {
        returnTo: document.location.origin
      }
    )
  }

}
