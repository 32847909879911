<h3>{{resultsSummary.title}}</h3>
<p>Name: <b>{{resultsSummary.name}}</b>&nbsp;&nbsp;&nbsp;Size: <i id="bronseStyle">{{humanSize}} </i><small
  *ngIf='resultsSummary.type==="CROP"'>&nbsp;&nbsp;&nbsp;Configuration: <a
  [href]="summaryConfigUrl$ | async" target="_blank">{{resultsSummary.configName}}</a></small>
</p>
<p>Process Type: {{resultsSummary.type}}</p>

<ng-template [ngIf]='resultsSummary.type==="HTQUANT"'>
  <p *ngIf='resultsSummary.type==="HTQUANT"'>Files Processed: {{resultsSummary.filesProcessed}}</p>
  <p *ngIf='resultsSummary.type==="HTQUANT"'>Fibers Found: {{resultsSummary.fibersFound}}</p>
  <p *ngIf='resultsSummary.type==="HTQUANT"'>Nuclei Found: {{resultsSummary.nuceliFound}}</p>
</ng-template>

<ng-template [ngIf]='resultsSummary.type==="CROP"'>
  <p>Path: {{resultsSummary.fileProcessed}}</p>
  <p-progressSpinner [style.display]="loadingImage ? 'block' : 'none'"></p-progressSpinner>

  <img class="responsive" [src]="summaryImageUrl$ | async"
       [style.display]="loadingImage ? 'none' : 'block'"
       (load)="setImageLoadingComplete($event)"
       (error)="onImageError($event)"/>

  <p-table [value]="results" [loading]="isChildrenLoading"
           styleClass="p-datatable-striped"
           [resizableColumns]="true"
           [scrollable]="true"
           scrollHeight="300px"
           (sortFunction)="alphaNumericSort($event)"
           [customSort]="true">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="key" [style]="{'width':'75px'}">Key
          <p-sortIcon field="key"></p-sortIcon>
        </th>
        <th pSortableColumn="name">Name</th>
        <th pSortableColumn="size">Size
          <p-sortIcon field="size"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-result>
      <tr>
        <td [style]="{'width':'75px'}">{{result.key}}</td>
        <td>{{result.name}}</td>
        <td>{{result.humanSize}}</td>
        <td><a [href]="result.previewUrl" target="_blank" class="fa fa-external-link-square"></a></td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
