import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MainFacade } from '../../main.facade';

import {
  BasicFile,
  DisplayType,
  FileFolder,
  FileProject,
  IFile,
  IFileInfo,
  ImageFile,
} from '../../models/file-info';
import { ResultsSummaryFacade } from '../results-summary/results-summary.facade';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileViewerComponent implements OnInit {
  readonly DisplayType = DisplayType;
  //readonly srcUsersGuidePdf = './assets/How-to-Use-JAX-Image-Tools.pdf';

  selectedFile: IFileInfo | null = null;
  displayType: DisplayType = DisplayType.None;
  isLoading!: boolean;

  isGrayscale = false;
  isStack = false;

  @ViewChild('viewer') canvasElement!: ElementRef;
  viewerDimensions: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([0, 0]);

  width = 0;
  height = 0;
  top = 0;
  left = 0;

  constructor(private resultsSummaryFacade: ResultsSummaryFacade, private mainFacade: MainFacade) {}

  ngOnInit(): void {
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.selectedFile = null;
        } else {
          this.selectedFile = node.data;
          if (this.selectedFile != null) {
            this.displayType = this.getDisplayType(this.selectedFile);
            this.mainFacade.selectDisplayType(this.displayType);
          }
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });

    this.mainFacade.isFileLoading$().subscribe({
      next: (isLoading) => {
        this.isLoading = isLoading;
      }
    })
  }

  private getDisplayType(file: IFileInfo): DisplayType {
    if (file instanceof FileProject) {
      return DisplayType.None;
    } else if (file instanceof FileFolder) {
      if (file.results) {
        this.resultsSummaryFacade.fetchResultsSummary();
        return DisplayType.ResultsSummary;
      } else {
        return DisplayType.None;
      }
    } else if (file instanceof ImageFile) {
      return DisplayType.Diagram;
    } else if (file instanceof BasicFile) {
      const selectedFile: IFile = <IFile>file;
      if (selectedFile.fileExtension === 'json' || selectedFile.fileExtension === 'xml') {
        this.mainFacade.fetchContent();
        return DisplayType.Code;
      } else if (selectedFile.fileExtension === 'csv') {
        this.mainFacade.fetchTableData();
        return DisplayType.TabDelimited;
      } else {
        return DisplayType.None
      }
    } else {
      console.error('Selected node is an unrecognized type');
      return DisplayType.None;
    }
  }

  setIsStack(isStack: boolean) {
    this.isStack = isStack;
  }
  setIsGrayscale(isGrayscale: boolean) {
    this.isGrayscale = isGrayscale;
  }
}
