import { Component, Input, OnInit } from '@angular/core';
import { TreeNode, MenuItem } from 'primeng/api';

import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'htquant',
  templateUrl: './htquant.component.html',
  styleUrls: ['./htquant.component.scss'],
})
export class HtquantComponent implements OnInit {

  form!: FormGroup;
  validationMessage: string | null = null;

  public bucket: string | undefined = undefined;
  public parentFolderPath: string | undefined = undefined;

  public mainTabMenu: MenuItem[] = [];

  // handles input field display
  isOutputFolderFiledEditable = false;

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });

    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          const selectedFile: IFileInfo | undefined = node.data;
          const parent: TreeNode<IFileInfo> | undefined = node.parent;

          if(selectedFile && selectedFile.project.bucket) {
            this.bucket = selectedFile.project.bucket;
          }

          if(parent && parent.data) {
            this.parentFolderPath = parent.data.relPath;
          }
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
  }

  configForm() {
    if (!this.processFacade.getHtQuantFormGroup()) {
      const form = this.fb.nonNullable.group({
        fiberSettings: this.fb.nonNullable.group({
          min: [20, Validators.required],
          max: [20000, Validators.required],
          cMin: [30, Validators.required],
          cMax: [100, Validators.required],
          hardBounds: [true],
        }),
        nucleiSettings: this.fb.nonNullable.group({
          min: [2.5, Validators.required],
          max: [50, Validators.required],
          cMin: [30, Validators.required],
          cMax: [100, Validators.required],
          hardBounds: [true]
        }),
        contrastProperties: this.fb.nonNullable.group({
          stretch: [true, Validators.required],
          saturated: [0.0, Validators.required],
          normalize: [true, Validators.required],
          gammaCorrection: [false, Validators.required],
          gamma: [1.0, Validators.required]         
        }),
        outputName: ['']
      });
      this.processFacade.setHtQuantFormGroup(form);
    }
    this.form = <FormGroup>this.processFacade.getHtQuantFormGroup();
  }

  onSubmit() {
    const quantRequest = RequestFactory.getQuantRequestFromForm(this.form.value);
    this.mainFacade.runProcess(quantRequest);
  }

  /* sets 'output folder' input field's editability */
  onEditOutputFolderName(val: boolean) {
    this.isOutputFolderFiledEditable = val;
  }
}
