import { ProcessType } from './request';

export class ResultsSummary {
  type?: ProcessType;
  name?: string;
  title?: string;
  fileProcessed?: string;
  configName?: string;
  proof?: string;
  regionsExtracted?: string[];
  directorySize?: number;
  fibersFound?: number;
  nuceliFound?: number;
  filesProcessed?: string[];
}

export function createEmptySummary(): ResultsSummary {
  return {
    'name' : 'No results',
    'title' : 'No segmentation results selected',
    'fileProcessed' : 'N/A',
    'configName' : 'default',
    'proof' : 'No proof created',
    'regionsExtracted' : [ ]
  }
}
