<div #viewer [ngClass]="isStack || isGrayscale ? 'file-viewer with-menu-height p-0 m-0 border-none'
  : 'file-viewer default-height p-0 m-0 border-none'">
  <ng-container [ngSwitch]="true">
    <div *ngSwitchCase="displayType === DisplayType.None" >
      <div id="cover">
        <!-- margin/padding to horizontally align the panel to the menu tabs on its sides -->
        <div id="image-files-security-container">
          <p-panel styleClass="border-noround" id="image-files-security-panel"
                   header="Data Security on JAX Image Tools"
                   [toggleable]="true" expandIcon="pi pi-fw pi-chevron-up"
                   collapseIcon="pi pi-fw pi-chevron-down">
            <!-- [NOTE: GIK 07/10/2023] - commented out due to stakeholder discussion -->
            <!--
            <pdf-viewer
              id="users-guide-manual"
              [src]="srcUsersGuidePdf"
              [render-text]="true"
              [original-size]="false"
              [show-all]="true"
              [zoom]="0.9"
              [zoom-scale]="'page-width'"
              [autoresize]="true"
              [show-borders]="false"
              class="h-30rem"
            ></pdf-viewer>
            <p class="text-justify font-medium line-height-3">
              <a
                href="https://jacksonlaboratory.sharepoint.com/:p:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7BE44F9388-729C-47B6-ACC2-196F89D71495%7D&file=How-to-use-JAX-image-tools.pptx&action=edit&mobileredirect=true"
                target="_blank"
              >Click here</a> to view the User's Guide document in full resolution.
            </p>
            -->
            <p class="text-justify font-medium line-height-3">
              In JAX Image Tools 2.0.0, images are not available to the
              general public - only users with valid SSO credentials can sign in
              and use the application.
            </p>
          </p-panel>
        </div>

        <!-- cloud buckets panel -->
        <div id="cloud-buckets-panel-container" class="my-4">
          <p-panel id="cloud-buckets-panel" styleClass="border-noround"
                   header="Cloud Buckets" [toggleable]="true" [collapsed]="true"
                   expandIcon="pi pi-fw pi-chevron-up"
                   collapseIcon="pi pi-fw pi-chevron-down">
            <p class="text-justify font-medium line-height-3">
              Data on the
              <a
                href="https://jacksonlaboratory.sharepoint.com/sites/Microscopy/SitePages/Cloud-Image-Tools.aspx"
                target="_blank"
              >JAX Image Tools</a> are stored in containers called
              <a href="https://cloud.google.com/storage/docs/buckets"
                target="_blank"
              >buckets</a> in Google Cloud Storage.
              All buckets are associated with a
              <a
                href="https://cloud.google.com/storage/docs/projects"
                target="_blank"
              >project</a>, and the projects are associated with an
              <a
                href="https://cloud.google.com/resource-manager/docs/cloud-platform-resource-hierarchy#organizations"
                target="_blank"
              >organization</a>.
            </p>

            <p class="text-justify font-medium line-height-2">
              Research labs and services can request a storage bucket within JAX-Cloud
              Image Tools project to store and analyze their data. Upload and download
              of data to and from the buckets is done via
              <a
                href="https://jacksonlaboratory.sharepoint.com/:p:/r/sites/Microscopy/_layouts/15/Doc.aspx?sourcedoc=%7BDE19B63A-9F37-483C-AF92-B606132A2BF3%7D&file=gsutil.pptx&action=edit&mobileredirect=true"
                target="_blank"
              >gsutil tool</a>. You can grant permission to make your
              data accessible to principals you specify and organize the file
              structure within the buckets according to your preference.
            </p>
          </p-panel>
        </div>

        <!-- file structure panel -->
        <div id="file-structure-panel-container" class="my-4">
          <p-panel id="file-structure" header="File Structure" styleClass="border-noround"
                   [toggleable]="true" [collapsed]="true"
                   expandIcon="pi pi-fw pi-chevron-up"
                   collapseIcon="pi pi-fw pi-chevron-down">
            <p class="text-justify font-medium line-height-3">
              There are essentially two different folder types: user-created and
              workflow generated. User can create their own file structure to organize
              data by creating folders and nested folders within their storage bucket.
              Workflow-generated folders appear in the parent folder and contain the
              results created by the execution of the workflow. Each workflow creates
              its own resulting files, for details please see below.
            </p>

            <h3>&bull; Slide Cropper</h3>
            <p class="text-justify font-medium line-height-3">
              Executing the 'Slide Cropper' workflow creates the output
              folder named after the parent image within the parent folder.
              The output folder will contain an overview image in JPEG format
              with the Region of Interests (ROIs) marked, cropped ROIs in TIFF
              or TIFF GZ format, preview images of the ROIs in PNG format, and JSON files.
            </p>

            <h3>&bull; Fibers and Nuclei</h3>
            <p class="text-justify font-medium line-height-3">
              In the 'Process' tab with the “Fibers and Nuclei” workflow selected,
              the user specifies the name of the output folder. After the successful
              completion of the workflow, the results appear inside the
              user-specified output folder within the parent folder.
              This workflow creates two CSV files - fiber_area.csv and nuclei_area.csv -
              and a folder that contains an XML metadata file, proof.tiffs,
              and JSON files for each ROI processed.
              <br/><br/>
              <b>Batch mode</b>: If you choose to output the
              results from multiple images into the same output folder,
              the two CSV files will get appended with the metrics of all
              fibers and nuclei identified from each of those processed
              images along with separate folders containing XML metadata file,
              proof.tiffs, and JSON files.
            </p>

            <h3>&bull; Image Alignment</h3>
            <p class="text-justify font-medium line-height-3">
              In the 'Process' tab with the 'Image Alignment' workflow selected, the
              user can specify the output folder and the output file names within
              the parent folder. This workflow creates two images in TIFF file format,
              a transformed image and an overlayed image.
            </p>

            <h3>&bull; Extended Depth of Field:</h3>
            <p class="text-justify font-medium line-height-3">
              In the 'Process' tab with the 'Extended Depth of Field' workflow selected,
              the user can specify the output folder and the output file name within the
              parent folder. This workflow creates a resultant image in TIFF file format.
            </p>

            <h3>&bull; Kidney Classifier:</h3>
            <p class="text-justify font-medium line-height-3">
              In the 'Process' tab with the 'Kidney Classifier' workflow selected,
              the user can analyze kidney histology, generating heatmaps for damage assessment,
              and the tool will provide detailed CSV reports at pixel, slide, and section levels,
              along with tissue masks.
            </p>
          </p-panel>
        </div> <!-- END File Structure Panel -->

        <!-- release notes panel -->
        <div id="release-notes-panel-container" class="my-4">
          <p-panel id="release-notes" header="Release Notes" styleClass="border-noround"
                   [toggleable]="true" [collapsed]="true"
                   expandIcon="pi pi-fw pi-chevron-up"
                   collapseIcon="pi pi-fw pi-chevron-down">

            For Version 2.0.0 (08/19/24):

            <h3 id="new-features-enhancements">New Features/Enhancements</h3>
            <ul>
              <li><p>High definition zooming : A new zoom feature is available through a new plotting system
                implementation. Users can zoom using a region of interest, or zoom-in/zoom-out/panning tool
                menu on the new plot implementation.</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-62">CIMG-62</a> Fix Zoom Feature for Images<br><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-131">CIMG-131</a> Create zoom backend for frontend to make arbitrary zooms at high resolutions</p>
                </blockquote>
              </li>
              <li><p>New plotting implementation : A new plotting system using the Plotly.js library is
                replacing the previous Draw2d implementation. The plot has many new features such as high
                def zooming, new region system/editor, new plot menus, visualization of image stacks,
                color mapping of grayscale images amongst other things.</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-258">CIMG-258</a> new visualization- plotly - a zoom-in navigate tool will be helpful </p>
                </blockquote>
              </li>
              <li><p>New Image Alignment/SIFT UI : The Image Alignment UI has been updated to include new
                parameters like &#39;matrix transformation&#39;, &#39;downsampling&#39;, &#39;saving of
                resized files&#39;, etc to support fluorescence images</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-340">CIMG-340</a> SIFT for fluorescence images<br><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-344">CIMG-344</a> Update Image alignment UI and backend to use the updated SIFT for fluorescence images</p>
                </blockquote>
              </li>
              <li><p>EDOF tool update : Added the ability to save the zmap file</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-342">CIMG-342</a> EDoF- Create UI component to output the output z-map  </p>
                </blockquote>
              </li>
              <li><p>New Kidney Classifier tool added</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-265">CIMG-265</a> Add Kidney Classifier Tool to UI</p>
                </blockquote>
              </li>
              <li><p>File permission added/modified through the creation of buckets per labs</p>
                <blockquote>
                  <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-381">CIMG-381</a> create buckets for each lab and move the data</p>
                </blockquote>
              </li>
              <li><p>Other improvements include : better loading UI when opening a folder, ability to
                resize the horizontal frames of the site (file navigator/file viewer/tool forms), better UI
                for the queuing status UI.</p>
              </li>
            </ul>
            <h3 id="bug-fixes">Bug Fixes</h3>
            <ul>
              <li>Some major bug fixes include <blockquote>
                <p><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-314">CIMG-314</a> HtQuant- corrupt proof tiffs<br><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-245">CIMG-245</a> The download does not work from the UI<br><a href="https://jacksonlaboratory.atlassian.net/browse/CIMG-298">CIMG-298</a> HtQuant- image file processed should be there on the error message. </p>
              </blockquote>
              </li>
            </ul>
            <h3 id="future-improvements">Future improvements</h3>
            <ul>
              <li>New color deconvolution tool to be soone added</li>
              <li>Ability to save/export and load/import regions of interests</li>
              <li>New image operations tool with computer vision algorithms to apply image processing to
                images and ROIs</li>
              <li>Enable SSO authentication through ORCiD (opening JIT to external users)</li>
              <li>Various minor bug fixes</li>
            </ul>


          </p-panel>
        </div> <!-- END Release notes -->
      </div>
    </div>

    <!--
      NB: draw2d canvas requires 'h-full' class to correctly function;

      renders draw2d canvas containing an image
    -->
    <div *ngSwitchCase="displayType === DisplayType.Diagram" class="h-full pt-2">
      <diagram id="diagram" (isStackEvent)="setIsStack($event)"
               (isGrayscaleEvent)="setIsGrayscale($event)"></diagram>
    </div>

    <!-- renders JSON content -->
    <div *ngSwitchCase="displayType === DisplayType.Code" class="h-full mt-6 pt-2">
      <code-panel></code-panel>
    </div>

    <!-- renders results summary -->
    <div *ngSwitchCase="displayType === DisplayType.ResultsSummary" class="h-full mt-6 pt-2">
      <results-summary></results-summary>
    </div>

    <!-- renders tabulated data -->
    <div *ngSwitchCase="displayType === DisplayType.TabDelimited" class="h-full mt-6 pt-2">
      <dynamic-table></dynamic-table>
    </div>
  </ng-container>
</div>
