import { Environment } from './environment.default';

export const environment: Environment = {
  production: false,
  version: require('../../../../package.json').version,
  auth: {
    domain: 'thejacksonlaboratory.auth0.com',
    clientId: 'TqMjJjlJW5PdFDQsfkUrhsvjoGk72jew',
    audience: 'https://cube.jax.org',
  },
  securedURLs: {
    cimg: 'https://cloud-image-tools-api-dev.jax.org',
  },
  unsecuredURLs: {},
  featureFlags: {},

  slideCropServer: 'https://cloud-image-tools-api-dev.jax.org/',
  temporalServer: 'http://localhost:8088/',
};
