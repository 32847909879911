<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p-divider align="left">
    <div class="inline-flex align-items-center font-bold">
      Images
    </div>
  </p-divider>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Alignment
      <i class="pi pi-info-circle px-1"
        pTooltip="Select RGB/Grayscale TIFF image. The image size (width x height in pixels)
                  of this image will be changed to the size of the reference image after the
                  alignment. Don’t select SHG or fluorescence image as the alignment image."
        tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        formControlName="alignFile"
        placeholder="SELECT ALIGNMENT"
        [options]="children"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-3 md:mb-0 text-black-alpha-90">
      Reference
      <i class="pi pi-info-circle px-1"
        pTooltip="Select bright field RGB/Grayscale TIFF image.
                  Don’t select SHG or fluorescence image as the
                  reference image."
        tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        formControlName="referenceFile"
        placeholder="SELECT REFERENCE"
        [options]="children"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>

  <div class="field grid">
    <label class="col-12 mb-2 md:col-3 md:mb-0 right-0 text-black-alpha-90">
      SHG/FL
      <i class="pi pi-info-circle px-1"
        pTooltip="Select RGB/Grayscale TIFF image. The image size (width x height in pixels)
                  of this image should match the image size of either Alignment/Reference image. "
        tooltipPosition="top"
      ></i>
    </label>
    <div class="col-12 md:col-9">
      <p-dropdown
        formControlName="shgFile"
        placeholder="SELECT SHG"
        [options]="children"
        optionLabel="name"
      ></p-dropdown>
    </div>
  </div>
  <div class="field grid">
    <div *ngFor="let matrixTransform of matrixTransforms"
         class="flex align-items-center field-checkbox ml-2 mr-2">
      <p-radioButton
        [inputId]="matrixTransform.key"
        [value]="matrixTransform.value"
        formControlName="transform" />
      <label [for]="matrixTransform.key" class="ml-2">
        {{ matrixTransform.name }}
      </label>
      <i class="pi pi-info-circle px-1"
         [pTooltip]="matrixTransform.info"
         tooltipPosition="bottom"
      ></i>
    </div>
  </div>
  <div class="field grid">
    <div class="card flex justify-content-center ml-2 mr-2">
      <p-inputSwitch inputId="flip" formControlName="flip" />
      <label class="ml-2">
        Vertical flip
        <i class="pi pi-info-circle px-1"
           pTooltip="Vertical flip yes or no (yes if one of the images used is from an inverted microscope)."
           tooltipPosition="bottom"
        ></i>
      </label>
    </div>
  </div>
  <div class="field grid">
    <div class="card flex justify-content-center ml-2 mr-2">
      <p-inputSwitch formControlName="downsample" />
      <label class="ml-2">
        Downsample
        <i class="pi pi-info-circle px-1"
           pTooltip="Choose this option if the image (width x height X no: of channels)>9e7.
                     To maintain the original resolution of the reference image, divide the
                      reference and all associated images into smaller regions (patches)."
           tooltipPosition="bottom"
        ></i>
      </label>
    </div>
  </div>
  <div class="field grid">
    <label class="col-12 mb-2 md:col-5 md:mb-0 text-black-alpha-90">
      Gamma correction
      <i class="pi pi-info-circle px-2"
         pTooltip="Gamma correction value for overlay"
         tooltipPosition="bottom"
      ></i>
    </label>
    <div class="col-12 md:col-7">
      <p-inputNumber
        inputId="gamma"
        formControlName="gamma"
        mode="decimal"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        [showButtons]="true"
        [min]="0"
        class="p-inputtext-sm"
      ></p-inputNumber>
    </div>
  </div>
  <!-- this section becomes visible when file gets selected -->
  <ng-container>
    <p-divider align="left">
      <div class="inline-flex align-items-center font-bold">
        File Destination
      </div>
    </p-divider>

    <p class="font-light text-xs my-2">
      Indicate which folder you would like the files to be stored in.
    </p>

    <!-- storage bucket label -->
    <div class="field grid py-0">
      <label for="fn-bucket"
             class="col-12 mb-2 md:col-4 md:mb-0 right-0 text-black-alpha-90 text-sm">
        Storage Bucket:
      </label>
      <div class="col-12 md:col-8 text-sm">
        <span id="fn-bucket">{{bucket}}</span>
      </div>
    </div>

    <div class="pt-3 pb-3">
      <span *ngIf="validationMessage" style="color: red">{{ validationMessage }}</span>
    </div>

    <!-- directory path label -->
    <div *ngIf="parentFolderPath" class="field grid py-0">
      <label for="fn-parent-folder"
             class="col-12 mb-2 md:col-4 md:mb-0 right-0 text-black-alpha-90 text-sm">
        Parent Folder:
      </label>
      <div class="col-12 md:col-8 text-sm">
        <span id="fn-parent-folder">{{parentFolderPath}}</span>
      </div>
    </div>

    <!-- output folder field -->
    <div class="field grid h-3rem align-items-center">
      <label for="fnOutputFolder" class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Output Folder:
      </label>
      <div class="col-12 md:col-7">
      <span *ngIf="!isOutputFolderFiledEditable">
        {{form.controls['outputFolder'].value}}
        <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOutputFolderName(true)"></i>
      </span>
        <span *ngIf="isOutputFolderFiledEditable" class="p-input-icon-right">
        <i class="pi pi-times icon-hover" (click)="onEditOutputFolderName(false)"></i>
        <input id="fnOutputFolder" type="text" class="p-inputtext-sm w-full"
               formControlName="outputFolder" pInputText/>
      </span>
      </div>
    </div>

    <!-- translated output -->
    <div class="field grid h-3rem align-items-center">
      <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Transformed Output:
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isTranslatedOutputEditable">
          {{form.controls['siftOutputName'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditTranslatedOutputName(true)"></i>
        </span>
        <span *ngIf="isTranslatedOutputEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditTranslatedOutputName(false)"></i>
          <input id="translatedOutput" class="p-inputtext-sm w-full" type="text"
                 formControlName="siftOutputName" pInputText/>
        </span>
      </div>
    </div>

    <div class="field grid h-3rem align-items-center">
      <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Overlayed Result:
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isOverlayedResultEditable">
          {{form.controls['overlayedResultName'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditOverlayedResultName(true)"></i>
        </span>
        <span *ngIf="isOverlayedResultEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditOverlayedResultName(false)"></i>
          <input id="overlayedResult" type="text" class="p-inputtext-sm w-full"
                 formControlName="overlayedResultName" pInputText/>
        </span>
      </div>
    </div>

    <div *ngIf="form.controls['downsample'].value && !form.controls['transform'].value"
         class="field grid h-3rem align-items-center">
      <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Resized NZ:
        <i class="pi pi-info-circle px-2"
           pTooltip="Resized NZ output file in case of downsample"
           tooltipPosition="bottom"
        ></i>
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isResizedNzEditable">
          {{form.controls['resizedNzName'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditResizedNzName(true)"></i>
        </span>
        <span *ngIf="isResizedNzEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditResizedNzName(false)"></i>
          <input id="resizedNzName" type="text" class="p-inputtext-sm w-full"
                 formControlName="resizedNzName" pInputText/>
        </span>
      </div>
    </div>

    <div *ngIf="form.controls['downsample'].value && form.controls['transform'].value"
         class="field grid h-3rem align-items-center">
      <label class="col-12 md:col-5 mb-0 text-black-alpha-90">
        Resized SF:
        <i class="pi pi-info-circle px-2"
           pTooltip="Resized SHG/FL output file in case of downsample"
           tooltipPosition="bottom"
        ></i>
      </label>
      <div class="col-12 md:col-7">
        <span *ngIf="!isResizedSfEditable">
          {{form.controls['resizedSfName'].value}}
          <i class="pi pi-pencil icon-hover mx-2" (click)="onEditResizedSfName(true)"></i>
        </span>
        <span *ngIf="isResizedSfEditable" class="p-input-icon-right">
          <i class="pi pi-times icon-hover" (click)="onEditResizedSfName(false)"></i>
          <input id="resizedSfName" type="text" class="p-inputtext-sm w-full"
                 formControlName="resizedSfName" pInputText/>
        </span>
      </div>
    </div>

    <div class="pt-3 pb-3 process-buttons">
      <span *ngIf="validationMessage" style="color: red" class="mb-2">{{ validationMessage }}</span>
      <p-button
        type="submit" icon="pi pi-play"
        label="Run"
        styleClass="p-button-primary">
      </p-button>
    </div>


  </ng-container>
</form>
