import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { MainFacade } from '../../../main.facade';
import { ProcessFacade } from '../process.facade';
import { FileFolder, IFile, IFileInfo } from '../../../models/file-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestFactory } from '../../../models/request.factory';

@Component({
  selector: 'kidney-classifier',
  templateUrl: './kidney-classifier.component.html',
  styleUrls: ['./kidney-classifier.component.scss'],
})
export class KidneyClassifierComponent implements OnInit {

  form!: FormGroup;
  public bucket: string | undefined = undefined;
  public parentFolderPath: string | undefined = undefined;
  validationMessage: string | null = null;

  
  public imageDirectories: IFileInfo[] = [];

  public models: string[] = [
    'Kidney Classifier/Models/LinkNet_KidneyAge.jld2'
  ];

  constructor(private mainFacade: MainFacade,
              private processFacade: ProcessFacade,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.configForm();
    this.mainFacade.getSelectedNode$().subscribe({
      next: (node) => {
        if (node == undefined || node.data == undefined) {
          this.bucket = undefined;
        } else {
          this.setFileDropdownData(node);
        }
      },
      error: (err) => {
        console.error(`Error with node selection: ${err}`);
      },
    });
    this.mainFacade.getValidationMessage$().subscribe({
      next: (message) => {
        this.validationMessage = message;
      }
    });
  }

  setFileDropdownData(node: TreeNode<IFileInfo>) {
    this.imageDirectories = []
    if (node) {
      const selectedFile: IFileInfo | undefined = node.data;
      if (selectedFile) {
        if (selectedFile instanceof FileFolder) {
          this.setChildrenFromParent(node);
        } else {
          this.setChildrenFromParent(node.parent);
        }
      }
    }
  }

  setChildrenFromParent(node: TreeNode<IFileInfo> | undefined) {
    if (node && node.children) {
      node.children.forEach(child => {
        if (child.data) {
          this.imageDirectories.push(child.data);
        }
      });
    }
  }


  configForm() {
    this.form = this.fb.group({
      imageDirectory: [null, Validators.required],
      modelFile: [this.models[0], Validators.required],
      tissueMaskPath: ['TISSUE_MASKS/', Validators.required],
      damageScorePath: ['DAMAGE_SCORE_CSVS/', Validators.required],
      heatmapPath: ['HEAT_MAPS/', Validators.required],
      editTissueMask: [false],
      editDamageScore: [false],
      editHeatmap: [false]
    });
  }

  onSubmit() {
    const request = this.form.value;
    const bucket = request.imageDirectory.project.bucket;
    const parentPath = this.buildPath(request.imageDirectory);
    const kidneyClassifierRequest = RequestFactory.getKidneyClassifierRequestFromForm(request, bucket, parentPath);
    this.mainFacade.runProcess(kidneyClassifierRequest);
  }

  buildPath(file: IFile): string {
    const index = file.relPath.indexOf(file.name);
    return file.relPath.substring(0, index);
  }
}


