import { Component, OnInit } from '@angular/core';
import { IFileInfo, ImageFile, BasicFile } from '../../models/file-info';
import { MainFacade } from '../../main.facade';


/**
 * NOTE: <details> is reserved HTML element - so this
 * component shall use <cimg-details> as a CSS selector
 */
@Component({
  selector: 'cimg-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  // needed to enable type checking within template
  public readonly ImageFile = ImageFile;
  public readonly BasicFile = BasicFile;

  fileInfo!: IFileInfo | undefined;

  constructor(public mainFacade: MainFacade) {}

  ngOnInit(): void {
    this.fileInfo = this.mainFacade.getSelectedFileInfo();
  }
}
