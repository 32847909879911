import { Injectable } from '@angular/core';
import { MainFacade } from '../../main.facade';
import { ResultsSummaryState } from './results-summary.state';
import { FilesService } from '../../../services/files.service';
import { IFileInfo, ImageFile, RawFileInfo } from '../../models/file-info';
import { ResultsSummary } from '../../models/results';
import { FileInfoFactory } from '../../models/file-info/file-info.factory';

@Injectable({
  providedIn: 'root',
})
export class ResultsSummaryFacade {

  private selectedFileInfo?: RawFileInfo;

  constructor(private state: ResultsSummaryState,
              private filesService: FilesService) {
  }

  getSummaryConfigUrl$() {
    return this.state.getSummaryConfigUrl$();
  }

  getSummaryImageUrl$() {
    return this.state.getSummaryImageUrl$();
  }

  getResults$() {
    return this.state.getResults$();
  }

  getResultsSummary$() {
    return this.state.getResultsSummary$();
  }

  getIsChildrenLoading$() {
    return this.state.getIsChildrenLoading$();
  }

  setIsImageLoading(isLoading: boolean) {
    this.state.setIsImageLoading(isLoading);
  }

  fetchResultsSummary(): void {
    this.selectedFileInfo = this.filesService.getSelectedFileInfo()?.rawData;
    if (this.selectedFileInfo) {
      this.state.setIsResultsSummaryLoading(true);
      this.filesService.getResultsSummary(this.selectedFileInfo).subscribe(
        (summary) => {
          this.processResultsSummary(summary);
        },
        error => {
          console.error('Failed to fetch results summary');
        },
        () => {
          this.state.setIsResultsSummaryLoading(false);
        }
      )
    }
  }

  private processResultsSummary(summary: ResultsSummary) {
    this.state.setResultsSummary(summary);
    this.setImageConfig(summary.proof);
    this.setResultConfig(summary.configName);
    this.loadChildren(summary.regionsExtracted);
  }

  private loadChildren(regionsExtracted: string[] | undefined) {
    this.state.setIsChildrenLoading(true);
    if (regionsExtracted && this.selectedFileInfo) {
      const children: IFileInfo[] = [];
      this.filesService.getChildren(this.selectedFileInfo).subscribe({
        next: (files: IFileInfo[]) => {
          children.push(...files);
        },
        error: err => {
          console.error(err);
        },
        complete: () => {
          this.processResults(regionsExtracted, children);
          this.state.setIsChildrenLoading(false);
        }
      });
    }
  }

  processResults(regions: any[], children: IFileInfo[]) {
    // Map child by name
    const map = new Map();
    children.forEach(child => {
      map.set(child.name, child);
    });

    const res : any[] = [];

    if (regions != undefined && regions.length > 0) {
      regions.forEach(path => {
        let split: string[] = path.split('/');
        const name: string = split[split.length - 1];
        const file: IFileInfo = map.get(name);
        split = name.split('_');
        const key : string = split[0];

        // Clone
        const newFile = FileInfoFactory.getFileInfo(file);
        const imageFile: ImageFile = <ImageFile>newFile;
        imageFile.width = 2048; // Open big
        res.push({
          key: key,
          name: name,
          size: imageFile.size,
          humanSize: this.filesService.getHumanFileSize(imageFile),
          previewUrl: this.filesService.getPreviewUrl(imageFile)
        })
      });
    }

    this.state.setResults(res);
  }

  private setImageConfig(proof: string | undefined) {
    // This is extremely hacky as we should be creating an ImageFile since the service expects an
    // object to be built in a specific structure to load files. However, the way we display
    // results is really a one-off on fetching files so it doesn't make sense to spend the time
    // to create clean code to manage this functionality so...just build the object w/o the
    // ImageFile/IFileInfo constraints.

    if (proof && this.selectedFileInfo) {
      const info = {
        project: this.selectedFileInfo.project,
        name: proof,
        type: 'File',
        relPath: this.selectedFileInfo.relPath + '/' + proof,
        width: 800,
      }

      const url: string = this.filesService.getHackedPreviewUrl(info);
      this.state.setIsImageLoading(true);
      this.state.setSummaryImageUrl(url);
    }
  }

  private setResultConfig(config: string | undefined) {
    // This is extremely hacky as we should be creating an ImageFile since the service expects an
    // object to be built in a specific structure to load files. However, the way we display
    // results is really a one-off on fetching files so it doesn't make sense to spend the time
    // to create clean code to manage this functionality so...just build the object w/o the
    // ImageFile/IFileInfo constraints.

    if (config && this.selectedFileInfo) {
      const info = {
        project: this.selectedFileInfo.project,
        name: config,
        type: 'File',
        relPath: this.selectedFileInfo.relPath + '/' + config,
      }

      const url: string = this.filesService.getHackedContentUrl(info);
      this.state.setSummaryConfigUrl(url);
    }
  }

  // private loadConfigChild(configName: string) {
  //   const config: IFileInfo = this.filesService.getChild(this.selectedFileInfo, summary.configName);
  // }

  public getHumanSize(resultsSummary: ResultsSummary) : string {
    if (resultsSummary?.directorySize) {
      return this.filesService.humanFileSize(resultsSummary.directorySize, true);
    } else {
      return '0 B';
    }
  }

}
