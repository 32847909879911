import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ResultsSummaryFacade } from './results-summary.facade';
import { IFileInfo } from '../../models/file-info';
import { ResultsSummary, createEmptySummary } from '../../models/results';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'results-summary',
  templateUrl: './results-summary.component.html',
  styleUrls: ['./results-summary.component.scss'],
})
export class ResultsSummaryComponent implements OnInit {

  resultsSummary!: ResultsSummary;
  humanSize!: string;
  results: any[] = [];
  loadingImage!: boolean;
  summaryImageUrl$!: Observable<string | null>;
  summaryConfigUrl$!: Observable<string | null>;

  // TODO - use observable diretly in template
  // isChildrenLoading$!: Observable<boolean>;
  isChildrenLoading = false;

  constructor(public facade: ResultsSummaryFacade) {
    this.summaryImageUrl$ = facade.getSummaryImageUrl$();
    this.summaryConfigUrl$ = facade.getSummaryConfigUrl$();
    // this.isChildrenLoading$ = facade.getIsChildrenLoading$();
  }

  ngOnInit(): void {
    this.loadingImage = false;
    this.resultsSummary = createEmptySummary();
    this.facade.getResultsSummary$().subscribe(
      (summary) => {
        this.resultsSummary = summary;
        this.humanSize = this.facade.getHumanSize(this.resultsSummary);
      }
    );
    this.facade.getResults$().subscribe(
      (results) => {
        this.results = results;
      }
    );
    this.facade.getIsChildrenLoading$().subscribe(
      (isChildrenLoading) => {
        this.isChildrenLoading = isChildrenLoading;
      }
    )
  }

  setImageLoadingComplete(event: Event) {
    this.facade.setIsImageLoading(false);
  }

  onImageError(event: Event) {
    console.error('Error loading image');
    console.error(event);
  }

  alphaNumericSort(event: SortEvent) {
    if (event) {
      event.data?.sort((data1, data2) => {
        const fieldName = <string>event.field;
        const value1 = data1[fieldName];
        const value2 = data2[fieldName];
        let result = null;

        if (value1 == null && value2 != null)
          result = -1;
        else if (value1 != null && value2 == null)
          result = 1;
        else if (value1 == null && value2 == null)
          result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
          result = value1.localeCompare(value2, 'en', { numeric: true });
        else
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        const order = <number>event.order;
        return (order * result);
      });
    }
  }

}
