<div *ngIf="processStatus" class="flex flex-column align-items-stretch flex-wrap justify-content-around p-2">
    <div class="flex flex-row flex-grow align-items-center my-2">
      <span class="workflow-id flex-2 text-sm font-italic">
               {{ processStatus.info }}
        <span *ngIf="processStatus.action !== 'NONE' && processStatus.action !== null">
          - {{processStatus.action}}</span>
      </span>
      <span class="status flex flex-1 justify-content-end">
          <span *ngIf="monitorStatuses.includes(state)"
                class="running-percent flex align-self-center text-sm mr-1">{{percentComplete + '%'}}</span>
          <i [ngClass]="determineIconFromState() + ' text-xl'"
             [pTooltip]="tooltipContent()" [tooltipPosition]="'left'"
             [tooltipStyleClass]="'text-sm'" [autoHide]="state !== State.ERROR"></i>
      </span>
    </div>
    <div class="flex flex-column mb-1">
      <span class="flex flex-wrap align-self-center text-sm">{{message}}</span>
    </div>
    <div class="flex flex-column mb-1">
      <span class="submission-input text-xs font-italic">
        {{'/' + trimBucket(processStatus.submissionInput)}}
      </span>
      <div *ngIf="processStatus.results">
        <div>
          <i class="pi pi-angle-double-down" style="font-size: .75rem"></i>
        </div>
        <div class="submission-input text-xs font-italic">
         {{'/' + trimBucketAndFolder(processStatus.results ? processStatus.results[0] : '') + '/' }}
        </div>
      </div>
    </div>
  <button *ngIf="showTerminate()"  (click)="onClickTerminate()" pButton type="button"
              label="Cancel" class="p-button-danger p-button-text p-button-sm terminate-button"></button>
</div>
