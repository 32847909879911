import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { MenuItem, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';

import { MainFacade } from '../../main.facade';

import { FileProject } from '../../models/file-info';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

  public projects!: FileProject[];

  public activeItemL$!: Observable<MenuItem | null>; // left panel active item
  public activeItemR$!: Observable<MenuItem | null>; // right panel active item
  public itemsTabMenu: MenuItem[] = [];

  detailsDisabled = true;

  user$ = this.auth.user$;

  constructor(
    public auth: AuthService,
    public mainFacade: MainFacade
  ) { }

  ngOnInit(): void {
    // TO-DO: this can be moved to utils or config file
    this.itemsTabMenu = [
      {
        id: 'files',
        label: 'Files',
        command: () => {
          this.mainFacade.selectTabMenuItemL$(this.itemsTabMenu[0]);
        }
      },
      {
        id: 'details',
        label: 'Details',
        command: () => {
          this.mainFacade.selectTabMenuItemL$(this.itemsTabMenu[1])
        },
        disabled: this.detailsDisabled
      },
      {
        id: 'process',
        label: 'Process',
        command: () => {
          this.mainFacade.selectTabMenuItemR$(this.itemsTabMenu[2]);
        }
      },
      {
        id: 'queue',
        label: 'Queue',
        command: () => {
          this.mainFacade.selectTabMenuItemR$(this.itemsTabMenu[3]);
        }
      }
    ];
    // create tab-menu observable
    this.mainFacade.createMainTabMenu(this.itemsTabMenu);
    // set selected tab-menu items (left and right panels)
    this.mainFacade.selectTabMenuItemL$(this.itemsTabMenu[0]);
    this.mainFacade.selectTabMenuItemR$(this.itemsTabMenu[2]);
    // get observable references - this way other components can update the
    // selected tab-menu item and this will update the template
    this.activeItemL$ = this.mainFacade.getSelectedTabMenuItemL$();
    this.activeItemR$ = this.mainFacade.getSelectedTabMenuItemR$();

    this.mainFacade.getProjects$().subscribe({
      next: projects => {
        this.projects = projects;
      }
    });

    this.auth.isAuthenticated$.subscribe({
      next: isAuthenticated => {
        if(isAuthenticated) {
          this.mainFacade.loadProjects();
        }
      },
      error: e => {
        // TODO [gik 01/03/2023] show user friendly message on the page
        // why the authentication attempt was not successful.
        console.log(e);
      }
    });

    this.mainFacade.isSelectedFileFolder$().subscribe((isFolder) => {
      if(isFolder || isFolder == null) {
        this.itemsTabMenu[1].disabled = true;
        this.mainFacade.createMainTabMenu(this.itemsTabMenu);
      } else {
        this.itemsTabMenu[1].disabled = false;
        this.mainFacade.createMainTabMenu(this.itemsTabMenu);
      }
    });
  }

  onProjectSelect(event: any): void {
    this.mainFacade.selectProject(event.value);
  }

  interuptPreview(): void {
    this.mainFacade.interuptPreview();
  }

  processSelected(): void {
    this.mainFacade.processSelected();
  }

  nodeExpand(node: TreeNode) {
    this.mainFacade.updateNode(node);
  }

  nodeSelect(node: TreeNode) {
    this.mainFacade.nodeSelect(node);
  }

  loadingDropdown() {
    if (this.projects.length <= 0){
      return 'pi pi-spin pi-spinner';
    } else {
      return '';
    }
  }

  splitDragEvent(event: any) {
    this.mainFacade.setPanelWidth(event.sizes[1]);
  }
}
