import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessStatus } from '../../../../models/process-status';
import { ProcessStatusFacade } from '../process-status.facade';

@Component({
  selector: 'process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessStatusComponent implements OnInit {

  processStatuses$!: Observable<ProcessStatus[]>;

  constructor(private facade: ProcessStatusFacade) {}

  ngOnInit(): void {
    this.processStatuses$ = this.facade.getProcessStatuses$();
  }

}
